import { addEdge, Background, ReactFlow, useEdgesState, useNodesState} from "@xyflow/react"
import { useCallback, useEffect, useState } from "react";
import "@xyflow/react/dist/style.css";
import { Divider, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";




export const MapaTrazabilidad = ({initialEdges, initialNodes, code, tipo}) => {

	  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
		const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
		const [selectedNode, setSelectedNode] = useState(null);

	useEffect(() => {
			console.log('cambio', code);
			setSelectedNode(null);
	}, [initialNodes]);

  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges],
  );

	const markNodeAsSelected = (node) => {
		return {
			...node,
			style: {
				...node.style,
				borderColor: "#9dab67",
				scale:1.5
			},
		};
	};

	const markNodeAsUnselected = (node) => {
    return {
      ...node,
      style: {
        ...node.style,
        borderColor: "#000",
				scale: 1
      },
    };
  }

	const onNodeClick = (event, node) => {
		setSelectedNode(node);
		setNodes((nodes) =>
			nodes.map((n) => (n.id === node.id ? markNodeAsSelected(n) : ["input", "output"].includes(n.type) ? n : markNodeAsUnselected(n)))
		);
	};
	

  return(	
		<>
			<div style={{width: '100%', paddingLeft: '14px', marginTop: 5}}>
				<Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
          >
            {`Diagrama ${tipo} ${code}`}
				</Typography>
			</div>
			<div
				className="horizontal-container"
				style={{ width: selectedNode !== null ? "95vw" : "92vw", height: "75vh", marginTop: 20}}
			>
				<ReactFlow
					nodes={nodes}
					edges={edges}
					onNodesChange={onNodesChange}
					onEdgesChange={onEdgesChange}
					onConnect={onConnect}
					onNodeClick={onNodeClick}
					nodesDraggable={false}  // No permite arrastrar nodos
					nodesConnectable={false} // No permite conectar nodos
					elementsSelectable={false}// Deshabilita el zoom con doble clic
				>
					<Background
						color="#000"
						bgColor="#fff"
						variant="dots"
						gap={12}
						size={1}
					/>
				</ReactFlow>
				{
					selectedNode !== null &&
					<div className="sidebar">
						<div className="sidebar-node-information">
							<div style={{display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
								<span style={{fontSize:20, fontWeight: 600}}>Detalles de Movimiento</span>
								<IconButton
									aria-label="open drawer"
									onClick={()=>setSelectedNode(null)}
									edge="start"
									sx={{
											color:'#B6B6B6',
											marginRight: 0,
											width:'auto',

									}}
								>
									<CloseOutlined />
								</IconButton>
							</div>
							<Divider  style={{width: '100%', border:'0.5px solid #ffffff91'}}/>
							{selectedNode && (
								<>
								{	//folio documento
									selectedNode?.data?.label?.props?.data?.documento && (
										<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15}}>
											<span style={{fontSize:16, fontWeight:400}}>
												Folio de documento:
											</span>
											<span style={{fontSize:16, fontWeight:600, marginLeft:5}}>
												{selectedNode?.data?.label?.props?.data?.documento}
											</span>
										</div>
									)
								}
								{	//etiqueta individual
									selectedNode?.data?.label?.props?.data?.etiqueta && (
										<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15}}>
											<span style={{fontSize:16, fontWeight:400}}>
												Etiqueta:
											</span>
											<span style={{fontSize:16, fontWeight:600, marginLeft:5}}>
												{selectedNode?.data?.label?.props?.data?.etiqueta}
											</span>
										</div>
									)
								}
								{/* {	//array de etiquetas
									selectedNode?.data?.label?.props?.data?.etiquetas && (
										<div style={{marginTop:15}}>
											<span style={{fontSize:16, fontWeight:400}}>
												Etiquetas
											</span>
											<div style={{display:'flex', flexDirection:'column', marginTop:5}}>
												{selectedNode?.data?.label?.props?.data?.etiquetas?.map((etiq, index) => (
													<li key={`etiqueta_${index}`} 	style={{color: 'white', fontWeight: 600}} >
														{etiq}
													</li>
												))}
											</div>
										</div>
									)
								} */}
								{	//almacen
									selectedNode?.data?.label?.props?.data?.almacen &&
									(
										<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15}}>
											<span style={{fontSize:16, fontWeight:400}}>
												Almacen:
											</span>
											<span style={{fontSize:16, fontWeight:600, marginLeft:5}}>
												{selectedNode?.data?.label?.props?.data?.almacen}
											</span>
										</div>
									)
								}
								{	//cantidad
									selectedNode?.data?.label?.props?.data?.cantidad &&
									(
										<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15}}>
											<span style={{fontSize:16, fontWeight:400}}>
												Cantidad:
											</span>
											<span style={{fontSize:16, fontWeight:600, marginLeft:5}}>
												{selectedNode?.data?.label?.props?.data?.cantidad}
											</span>
										</div>
									)
								}
								{	//ordenes
									selectedNode?.data?.label?.props?.data?.orden &&
									(
										<div style={{marginTop:15}}>
											<span style={{fontSize:16, fontWeight:400}}>
												Ordenes:
											</span>
											<div style={{display:'flex', flexDirection:'column', marginTop:5}}>
												{selectedNode?.data?.label?.props?.data?.orden?.map((resp, index) => (
													<>
														{
															resp !== null && (
																<li key={`orden_${index}`} 	style={{color: 'white', fontWeight:600}} >
																	{resp}
																</li>
															)
														}
													</>
												))}
											</div>
										</div>
									)
								}
								{	//seriales
									selectedNode?.data?.label?.props?.data?.serial_cantidad &&
									(
										<div style={{marginTop:15}}>
											<span key='title_cs' style={{fontSize:16, fontWeight:400}}>
												{
													selectedNode?.data?.label?.props?.data?.etiquetas ? 'Etiquetas y Cantidad:' : 'Seriales y Cantidad:'
												}
											</span>
											<div style={{display:'flex', flexDirection:'column', marginTop:5}}>
												{selectedNode?.data?.label?.props?.data?.serial_cantidad?.map((resp, index) => (
													<>
														{
															resp !== null && (
																<li key={`sc_${index}`} 	style={{color: 'white', fontWeight:600}} >
																	{resp?.moin_serial} - ({resp?.cantidad})
																</li>
															)
														}
													</>
												))}
											</div>
										</div>
									)
								}
								
								<div style={{marginTop:15}}>
									<span style={{fontSize:16, fontWeight:400}}>
										Responsables y fechas de movimientos
									</span>
									<div style={{display:'flex', flexDirection:'column', marginTop:5}}>
										{selectedNode?.data?.label?.props?.data?.responsables?.map((resp, index) => (
											<li key={`resp_${index}`} 	style={{color: 'white', fontWeight:600}} >
												{resp}
											</li>
										))}
									</div>
								</div>
								</>
							)}
						</div>
				</div>
				}
			</div>
		</>
  )
}