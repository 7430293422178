import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import React, { useState } from "react";
import { AreaAlmacenEnum } from "../../../../../@enums/tipo_almacen";


const FormOptions = ({step, updateRuta, index, length_steps}) => {

  const [sizeLg, setSizeLg] = useState(index === 0 ? 4 : 3)

  return(
    <>
      <Grid item xs={6} sm={sizeLg} md={sizeLg} lg={sizeLg} xl={sizeLg} sx={{p:0}} >
          <FormControlLabel
              name="rupr_codigosustitutoparte"
              control={
                  <Checkbox
                  name="rupr_codigosustitutoparte"
                  defaultChecked={
                      step?.rupr_codigosustitutoparte
                  }
                  onChange={(e)=> updateRuta(e, step?.rupr_id, 'code', index)}/>
              }
              label="Cambio de código"
              labelPlacement="end"
              sx={{width:'100%'}}
              />
      </Grid>
      <Grid item xs={6} sm={sizeLg} md={sizeLg} lg={sizeLg} xl={sizeLg} sx={{p:0}} >
          <FormControlLabel
              name="tag"
              control={
                  <Checkbox 
                  defaultChecked={
                      step?.foet_id
                  }
                  onChange={(e)=> {
                      updateRuta(e, step?.rupr_id, 'tag', index)
                  }}
                  />
              }
              label="Etiqueta"
              labelPlacement="end"
              sx={{width:'100%'}}
              />
      </Grid>
      {index === 0 &&
        <>
					<Grid item xs={6} sm={sizeLg} md={sizeLg} lg={sizeLg} xl={sizeLg} sx={{p:0}} >
						<FormControlLabel
						name="tag_reporte"
						control={
								<Checkbox 
								defaultChecked={
										step?.foet_reporteoid
								}
								onChange={(e)=> {
										updateRuta(e, step?.rupr_id, 'tag_reporte', index)
								}}
								/>
						}
						label="Etiqueta de traspaso"
						labelPlacement="end"
						sx={{width:'100%'}}
						/>
					</Grid>
					<Grid item xs={6} sm={sizeLg} md={sizeLg} lg={sizeLg} xl={sizeLg} sx={{p:0}} >
						<FormControlLabel
							name="certificate_reporteoid"
              control={
                  <Checkbox 
                  name="certificate_reporteoid"
                  defaultChecked={
                      step?.cues_reporteoid !== null
                  }
                  onChange={(e)=> updateRuta(e, step?.rupr_id, 'certificate_reporteoid', index)}/>
              }
              label="Revisión de traspaso"
              labelPlacement="end"
              sx={{width:'100%'}}
              />
					</Grid> 
				</>	
      }
      <Grid item xs={6} sm={sizeLg} md={sizeLg} lg={sizeLg} xl={sizeLg} sx={{p:0}} >
          <FormControlLabel
              name="rupr_continuo"
              control={
                  <Checkbox 
                  name="rupr_continuo"
                  defaultChecked={
                      step?.rupr_continuo
                  }
                  onChange={(e)=> updateRuta(e, step?.rupr_id, 'continue', index)}
                  disabled={step?.almacenes?.alma_area !== AreaAlmacenEnum.PRODUCCION || index === length_steps-1}/>
              }
              label="Continuo"
              labelPlacement="end"
              sx={{width:'100%'}}
              />
      </Grid>
      <Grid item xs={6} sm={sizeLg} md={sizeLg} lg={sizeLg} xl={sizeLg} sx={{p:0}} >
          <FormControlLabel
              name="rupr_revisionentradasalida"
              control={
                  <Checkbox 
                  name="rupr_revisionentradasalida"
                  defaultChecked={
                      step?.rupr_revisionentradasalida
                  }
                  onChange={(e)=> updateRuta(e, step?.rupr_id, 'certificate', index)}/>
              }
              label="Revisión E/S"
              labelPlacement="end"
              sx={{width:'100%'}}
              />
      </Grid>
			{
				step?.almacenes?.alma_area === AreaAlmacenEnum.CALIDAD && 
				(
					<Grid item xs={6} sm={sizeLg} md={sizeLg} lg={sizeLg} xl={sizeLg} sx={{p:0}} >
						<FormControlLabel
						name="rupr_generamasterreporteo"
						control={
							<Checkbox 
							name="rupr_generamasterreporteo"
							defaultChecked={
									step?.rupr_generamasterreporteo
							}
							onChange={(e)=> updateRuta(e, step?.rupr_id, '', index)}/>
						}
						label="Master default"
						labelPlacement="end"
						sx={{width:'100%'}}
						/>
					</Grid>
				)
			}
    </>
  )
}

export default FormOptions;