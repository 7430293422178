import { ThemeContext } from "@emotion/react";
import { Alert, Backdrop, Box, Button, Chip, Fade, FormControl, FormControlLabel, Grid, Modal, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react";
import { VerifyMov } from "../../../../../hooks/verifyMov";
import swal from "sweetalert2";
import { EstatusMovimientoEnum } from "../../../../../@enums/status_mov_inv";
import { useLoading } from "../../../../../components/Loading/useLoading";
import { Delete } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { MergeContainers } from "../../../../../hooks/merge-split";
import { TicketLocal } from "../../../../../hooks/sendPrint";

export const ModalMerge = ({open, setOpen, almacen}) => {

  const style = {
    position: "absolute",
    top: "43%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 500,
    maxWidth: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    display: "flex",
    justifyContent: "center",
  };
  const formData = useRef(null);
  const inputRefMerge = useRef(null);
  const inputContenedores = useRef(null);
  const {setAppLoading} = useLoading();
  const theme = useContext(ThemeContext);
  const { verifyMovMutation } = VerifyMov();
  const { mergeContainersMutation } = MergeContainers();
  const { ticketLocalMutation } = TicketLocal();
  const [seccion, setSeccion] = useState(1);
  const [paquetes, setPaquetes] = useState([]);
  const [paqueteSplit, setPaqueteSplit] = useState(null);
  const [contenedores, setContenedores] = useState(0);
  const [alertSplit, setAlertSplit] = useState(false);
  const printerId = useSelector((state) => state.app.printerId);

  useEffect(() => {
    if (open) {
        setTimeout(() => {
            if (inputRefMerge.current) {
              inputRefMerge.current.focus();
            }
        }, 200); // Esperar un corto tiempo para asegurarse de que el modal esté completamente renderizado
    }
  }, [open]);


  const handleClose = () => {
    setOpen(false);
    setPaquetes([]);
    setPaqueteSplit(null);
    setAlertSplit(false);
    setContenedores(0);
    setSeccion(1);
  }
  const changeSeccion = (e) => {
    setSeccion(parseInt(e.target.value));
    setPaquetes([]);
    setPaqueteSplit(null);
    setAlertSplit(false)
    formData.current.etiqueta.value = "";
  }
  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      setAppLoading(true)
      event.preventDefault();
      if (paquetes.some((item) => item.tag === event.target.value)) {
        setAppLoading(false)
        swal.fire({
          position: "center",
          icon: "warning",
          title: "El contenedor ya ha sido agregado",
          width: 600,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            container: "cont_alt",
          },
        });
      } else {
        let body = {
          moin_estatus: EstatusMovimientoEnum.ACTIVO,
          alma_entrada: almacen,
          movimientos_inventario_etiquetas: {
            etiquetas: {
              etiq_estatus:true,
              etiq_codigo: event.target.value,
              etiq_padreid: null,
              etiq_ismaster: false
            }
          }
        };
        verifyMovMutation({where: body, validate_master: true, where_master: {}}, {
          onSuccess: (data) => {
            setAppLoading(false)
            if (data.success !== false && (paquetes.length === 0 || (paquetes.length > 0 && data?.data?.parte === paquetes[0].parte))) {
              if(seccion === 1){
                setPaquetes([
                  ...paquetes,
                  {
                    ...data.data,
                  },
                ]);
                formData.current.etiqueta.value = "";
                inputRefMerge.current.focus();
              }else{
                setPaqueteSplit(data?.data);
                calculateSplit(data?.data?.cantidad, contenedores)
                inputContenedores.current.focus();
              }
            } else {
              swal.fire({
                position: "center",
                icon: "error",
                title:
                  "El contenedor no existe o no cumple con las condiciones necesarias",
                width: 600,
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  container: "cont_alt",
                },
              });
              inputRefMerge.current.focus();
            }
          },
          onError: (error) => {
            setAppLoading(false)
            swal.fire({
              position: "center",
              icon: "error",
              title:
                "El contenedor no existe o no cumple con las condiciones necesarias",
              width: 600,
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                container: "cont_alt",
              },
            });
            inputRefMerge.current.focus();
          },
        });
      }
    }
  };
  const changeCantidad = (e) => {
    if(e.target.value === ''){
      setContenedores(0);
      calculateSplit(paqueteSplit?.cantidad, 0)
    }else{
      let cantidad = parseInt(e.target.value)
      setContenedores(cantidad)
      if(paqueteSplit !== null){
        calculateSplit(paqueteSplit?.cantidad, cantidad)
      }
    }
  }
  const deleteItem = (index) => {
    let paq = paquetes;
    paq.splice(index, 1);
    setPaquetes([...paq]);
  }

  const calculateSplit = (cantidad_split, total_contenedores) => {
    let total = cantidad_split;
    let cantidad_contenedores = total_contenedores;
    if(cantidad_contenedores > 0){
      let paq = new Array(cantidad_contenedores);
      let cociente = Math.floor(total/ cantidad_contenedores);
      let residuo = total % cantidad_contenedores;
      paq.fill({cantidad: cociente});
      if(residuo>0){
        paq.push({cantidad: residuo});
        setAlertSplit(true)
      }else{
        setAlertSplit(false)
      };
      setPaquetes(paq)
    }else{
      setPaquetes([]);
    };
  }

  const sendData = () => {
    setAppLoading(true)
    let body = {
      printerId: printerId,
      almacenOrigen: almacen,
      tipo: seccion,
      data: paquetes,
      tag: paqueteSplit?.tag_id
    }
    mergeContainersMutation(body, {
      onSuccess: (data) => {
        for(let print of data?.data){
          ticketLocalMutation(print?.print, {
              onSuccess: (data) => {
                  console.log(data)
              },
              onError: (error) => {
                  console.log(error)
              }
          })
        }
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error);
        setAppLoading(false)
      }
    })
    handleClose();
  }

  return(
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} className="modal_merge">
          <Grid container spacing={0}>
            <Grid
            item
            xs={12}
            sx={{ p: 0, mb: 1, pr: 1 }}
            className="center_item"
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {
                  `
                  ${seccion === 1 ? 'Merge' : 'Split'}
                  de Contenedores
                  `
                }
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              xl={4}
              sx={{
                p: 1,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
                flexDirection: "column",
              }}
            >
              <form ref={formData} id="form_dom" style={{ width: "100%" }}>
                <FormControl sx={{mb:2}}>
                  <RadioGroup
                    aria-labelledby="radio_merge"
                    name="radio_merge"
                    value={seccion}
                    onChange={changeSeccion}
                    row
                  >
                    <FormControlLabel value={1} control={<Radio />} label="Merge" />
                    <FormControlLabel value={2} control={<Radio />} label="Split" />
                  </RadioGroup>
                </FormControl>
                <TextField
                  id="etiqueta"
                  size="small"
                  label="Etiqueta"
                  fullWidth
                  inputRef={inputRefMerge}
                  onKeyDown={handleEnterPress}
                  disabled = {paqueteSplit !== null && seccion === 2}
                  sx={{ color: theme.palette.primary.input, width: "100%" }}
                />
                {
                  seccion === 2 &&
                  <TextField
                  type="number"
                  id="number_split"
                  size="small"
                  label="N° de contenedores"
                  fullWidth
                  inputRef={inputContenedores}
                  onChange={changeCantidad}
                  sx={{ color: theme.palette.primary.input, width: "100%", mt:2}}
                />
                }
                <Button
                variant="contained"
                className="btn_system"
                sx={{ fontSize: 12, height: 35, mt:2.5 }}
                disableElevation
                disabled={paquetes.length === 0}
                onClick={sendData}
                >
                  {
                    seccion === 1 ?
                    'Merge' :
                    'Split'
                  }
                </Button>
              </form>
            </Grid>
            <Grid
              item
              key='column_paquetes'
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              sx={{
                p: 1,
                gap: 2,
                display: "flex",
                alignItems: "flex-start",
                width: '100%'
              }}
              >
                <Grid
                container
                spacing={0}
                sx={{width:'100%'}}
                >
                  {
                    alertSplit &&
                    <Grid item xs={12} key='alert_split'  sx={{mb:2}}>
                      <Alert severity="warning" style={{textAlign: "center", display: "flex", justifyContent: "center"}}>
                        El numero de contenedores genera un contenedor residuo
                      </Alert>
                    </Grid>
                  }
                  {
                    paqueteSplit !== null &&
                    <Grid item xs={12} key='total_split'  sx={{mb:0.5, p:0.5}}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          padding: 0,
                          height: '30px'
                        }}
                      >
                        <p style={{padding: 0}}>
                          Cantidad total:  
                          <span style={{fontWeight: 600}}> {paqueteSplit?.cantidad}</span>
                        </p>
                      </div>
                    </Grid>
                  }
                  <Grid 
                  item 
                  xs={12}
                  sx={{
                    p: 1,
                    border: "1px solid #D9D9D9",
                    borderRadius: 5,
                  }}
                  >
                    <Grid
                    container
                    spacing={0}
                    sx={{
                      width:'100%',
                      minHeight: '150px',
                      maxHeight: '400px',
                      overflowY: 'scroll'
                    }}
                    >
                      {paquetes?.map((paq, index) => (
                          <>
                          {
                            <Grid
                            item
                            xs={6}
                            sx={{
                              p: 1,
                              textAlign: "start",
                              display: "flex",
                              alignItems: "flex-start",
                              alignContent: 'flex-start',
                              width: '100%',
                              height: '50px'
                            }}
                            >
                              <Chip
                                label={
                                  <div
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        marginRight: 7,
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "black",
                                      }}
                                    >
                                      {paq?.cantidad}
                                    </span>
                                    {
                                      seccion === 1 &&
                                        <span
                                        style={{
                                          marginLeft: 7,
                                          border: "1px solid #575E3B",
                                          borderRadius: 4,
                                          fontSize: 11,
                                          padding: 3,
                                          color: "#575E3B",
                                        }}
                                      >
                                        {paq?.tag}
                                      </span>
                                    }
                                  </div>
                                }
                                variant="outlined"
                                deleteIcon={
                                  <Delete sx={{ marginLeft: "5px !important", width:18 }} />
                                }
                                onDelete={
                                  seccion === 1 ?
                                  ()=>deleteItem(index) :
                                  null
                                }
                                sx={{
                                  height: 40,
                                  borderRadius: 2,
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                  backgroundColor: "transparent",
                                }}
                              />
                            </Grid>
                          }
                          </>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  )
}