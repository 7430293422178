import { useEffect, useState } from "react";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Card, Checkbox, FormControl, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLoading } from "../../../components/Loading/useLoading";
import { CrearReporte, GetEntity } from "../../../hooks/reporteQuery";
import { ArrowBack, ArrowForward, ExpandMore, Search } from "@mui/icons-material";
import { SideList } from "./components/sideList";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";


export const NuevaQuery = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {setAppLoading} = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const { getEntityMutation } = GetEntity();
  const { crearReporteMutation } = CrearReporte();
  const [reportName, setReportName] = useState(null);
  const [reportDesc, setReportDesc] = useState(null);

  //Transferlist
  const [originalList, setOriginalList] = useState([]);
  const [leftList, setLeftList] = useState([]);
  const [rightList, setRightList] = useState([]);
  const [selectedToLeft, setSelectedToLeft] = useState([]);
  const [selectedToRight, setSelectedToRight] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [listaFiltrada, setListaFiltrada] = useState([]);

  useEffect(() => {
    dispatch(
        HEADER_DATA_UPDATE({nombreData: 'Nuevo Reporte'})
    )
    get_entity();
  }, []);

  const get_entity = () => {
    setAppLoading(true)
    getEntityMutation({}, {
      onSuccess: (data) => {
        setLeftList(data?.data);
        setListaFiltrada(data?.data)
        setOriginalList(data?.data)
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error);
        setAppLoading(false);
      }
    })

  }

  const handleSelect = (side, col, alias) => {

    let data = side === 'left' ? structuredClone(selectedToRight) : structuredClone(selectedToLeft);
    let data_left = null;
    let entity = alias !== undefined && alias !== null ?
      data.findIndex((item) => item.alias === alias) :
      data.findIndex((item) => item.name === col.main_entity)
    
    if(side === 'left' && col.relation_name){
      data_left = [...leftList];
      let entity = data_left.findIndex((item) => item.name === col.main_entity);
      let item_exist = data_left[entity].columns.filter(item => item.relation_name === col.relation_name);
      console.log(item_exist)
      if(item_exist.length > 1){
        let item_add = data_left.find(item => item.name === col.relation_name);
        let exist_alias = data_left.findIndex((ent) => ent.alias === col.name);
        if(exist_alias === -1){
          data_left.push({
            name: item_add.name,
            columns: item_add.columns,
            relations: item_add.relations,
            alias: col?.name
          })
        }
        setLeftList([...data_left]);
      }
    }
    if(entity !== -1){
      const index = data[entity]?.columns?.findIndex(
        (item) => item.name === col.name
      );
      if (index !== -1) {
        // Si el objeto existe, lo eliminamos
        data[entity].columns.splice(index, 1)
      } else {
        // Si no existe, lo agregamos
        data[entity].columns.push(col)
      }
    }else{
      let entity = originalList.find(item => item.name === col?.main_entity);
      let new_data = {
        name: col?.main_entity,
        columns: [col],
        relations: entity?.relations,
        alias
      }
      console.log(new_data)
      data.push(new_data)
    }


    side === 'left' ? setSelectedToRight([...data]) : setSelectedToLeft([...data]);

  }

  const moveItems = (side) => {
    let moveItems = side === 'left' ? structuredClone(selectedToRight) : structuredClone(selectedToLeft);
    let deleteItems = side === 'left' ? structuredClone(leftList) : structuredClone(rightList);
    let actualItems = side === 'left' ? structuredClone(rightList) : structuredClone(leftList);
    let setItems = side === 'left' ? setRightList : setLeftList;
    let setItemsDel = side === 'left' ? setLeftList : setRightList;
    //Agregar al lado a mover
    for(let entity of moveItems){
      let index_ent = entity?.alias !== undefined && entity?.alias !== null ?
      actualItems.findIndex((item) => item.alias === entity.alias) :
      actualItems.findIndex((item) => item.name === entity.name);
      let index_del = entity?.alias !== undefined && entity?.alias !== null ?
      deleteItems.findIndex((item) => item.alias === entity.alias) :
      deleteItems.findIndex((item) => item.name === entity.name);
      if(index_ent !== -1){
        entity.columns.map(col => {
          let index = actualItems[index_ent].columns.findIndex((item) => item.name === col.name);
          if(index === -1){
            actualItems[index_ent].columns.push(col)
          }
        })
      }else{
        actualItems.push(entity)
      }

      if(index_del !== -1){
        entity.columns.map(col => {
          let index = deleteItems[index_del].columns.findIndex((item) => item.name === col.name);
          if(index !== -1){
            deleteItems[index_del].columns.splice(index, 1);
          }
        })
      }

      if(deleteItems[index_del].columns.length === 0){
        deleteItems.splice(index_del, 1)
      }
    }

    if(side === 'right'){
      let flag = true;
      while (flag) {
        flag = false;
        let verifyItems = [...deleteItems];
        deleteItems.map((entity, index) => {
          if(index !== 0){
            const relationsSet = new Set(entity.relations);
            let items_filter = verifyItems.filter(item => relationsSet.has(item.name));
            if(items_filter.length > 0){
              let exist = items_filter.some(item => 
                item?.columns?.some(col => 
                  col.relation_name === entity.name || col.target_entity_inter === entity.name)
              )
              console.log(entity.name)
              console.log(exist)
              if(!exist){
                flag = true;
                let index_del = verifyItems.findIndex((item) => item.name === entity.name);
                let cols_to_add = verifyItems[index_del]?.columns;
                let index_add = actualItems.findIndex(item => item.name === entity.name);
                actualItems[index_add].columns.push(...cols_to_add);
                verifyItems.splice(index_del, 1);
              }
            }
          }
        })
        deleteItems = [...verifyItems]
      }
    }
    setItems([...actualItems]);
    setItemsDel([...deleteItems]);
    setSelectedToLeft([])
    setSelectedToRight([])
  }

  const createReportFields = () => {
    let data = [...rightList];

    let report_fields = [];
    for(let entity of data){
      let alias = entity.alias;
      for(let column of entity.columns){
        if(column.target_entity_inter){
          let exist = data.some(item => item.alias === column.name)
          let field = {
            type: 'join',
            field: column.origin_key,
            field_name: `Ref ${column.target_entity_inter}`,
            field_origin: column.main_entity,
            middle_table: column.relation_name,
            relation_type: column.relation_type,
            field_target: column.target_entity_inter,
            field_target_key: column.final_key,
            alias: exist ? column.name : null
          }
          report_fields.push(field)
        }else if(column.relation_name){
          let exist = data.some(item => item.alias === column.name)
          let field = {
            type: 'join',
            field: column.name,
            field_name: '',
            field_origin: column.main_entity,
            middle_table: null,
            relation_type: column.relation_type,
            field_target: column.relation_name,
            field_target_key: column.reference_db ? column.reference_db : column.name,
            alias: exist ? column.name : null
          }
          report_fields.push(field)
        }else{
          let field = {
            type: 'field',
            field: column.name,
            fiel_type: column.data_type,
            field_name: column.name,
            field_origin: column.main_entity,
            middle_table: null,
            relation_type: null,
            field_target: null,
            field_target_key: null,
            alias
          }
          report_fields.push(field)
        }
      }
    }
    let response = {
      initial_entity: data[0].name,
      report_fields,
      whereConditions: []
    }
    return response
  }

  const sendToSave = () => {
    if(reportName === null){
      enqueueSnackbar( 'Complete los campos requeridos', {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }else{
      setAppLoading(true);
      let data_fields = createReportFields();
      let body = {
        repo_nombre: reportName,
        repo_desc: reportDesc,
        repo_fields: JSON.stringify(data_fields)
      }
      crearReporteMutation(body, {
        onSuccess: (data) => {
          setAppLoading(false);
          enqueueSnackbar( data?.mensaje, {
            autoHideDuration: 2000,
            variant: data?.success ? "success" : "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
          navigate(`/reportes/personalizado`);
        },
        onError: (error) => {
          console.log(error)
        }
      })
    }
  }

  //FILTRO

  const search = (value) => {
    setFiltro(value)
    if(value === ""){
      setListaFiltrada([...leftList])
    }else{
      let data = leftList.map((item, index) => ({ ...item, indexOriginal: index }))
      .filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
      setListaFiltrada([...data])
    }
  }


  return(
    <>
      <Grid container spacing={0} sx={{mt:1}}>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={{p:1}} className="center_item">
          <FormControl fullWidth size="small" error={reportName === null}>
            <TextField
            id="report_name"
            label="Nombre reporte"
            name="report_name"
            size="small"
            fullWidth
            value={reportName}
            required
            sx={{
              mt: reportName === null ? 2 : 0
            }}
            onChange={
              (e) => {
                e.target.value === '' ? 
                setReportName(null) : 
                setReportName(e.target.value)
              }
            }
            />
            <FormHelperText>{reportName === null ? 'Ingrese un nombre' : ''}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}  sx={{p:1}} className="center_item">
          <FormControl fullWidth size="small">
            <TextField
              id="report_desc"
              label="Descripción reporte"
              name="report_desc"
              size="small"
              fullWidth
              value={reportDesc}
              onChange={
                (e) => {
                  e.target.value === '' ? 
                  setReportDesc(null) : 
                  setReportDesc(e.target.value)
                }
              }
              />
            <FormHelperText>{''}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={0} sm={0} md={0} lg={5} xl={5} sx={{p:1}} className="center_item">
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={2} xl={2} sx={{p:1}} className="center_item">
          <Button 
            variant="contained" 
            className="btn_system"
            onClick={sendToSave}
            >
              Guardar
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" sx={{p:2}}>
        <Grid item xs={5} sx={{p:2, flexDirection: 'column'}} className="center_item">
          <TextField
            id="search_entity"
            label="Entidad"
            name="search_entity"
            size="small"
            fullWidth
            value={filtro}
            sx={{mb:2}}
            InputProps={{
              endAdornment: <Search/>
            }}
            onChange={(e) => search(e.target.value)}
            />
          <SideList key='left_list' entities={listaFiltrada} selectedTo={selectedToRight} side='left' handleSelect={handleSelect} rightList={rightList}/>
        </Grid>
        <Grid item xs={2} sx={{p:1, alignItems: 'center', flexDirection: 'column', gap:2}} className="center_item">
          <Button variant="contained" className="btn_system" onClick={()=>moveItems('left')}> &gt; </Button>
          <Button variant="contained" className="btn_system" onClick={()=>moveItems('right')}> &lt; </Button>
        </Grid>
        <Grid item xs={5} sx={{p:1}} className="center_item">
          <SideList key='right_list' entities={rightList} selectedTo={selectedToLeft} side='right' handleSelect={handleSelect} rightList={rightList}/>
        </Grid>
      </Grid>
    </>
  )
}