import { Backdrop, Box, Chip, Fade, Grid, Modal, Typography } from "@mui/material"
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 500,
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  display: "flex",
  justifyContent: "center",
};

export const Contenedores = ({open, setOpen, data, orden, parte, empresa, setContenedores, getSalidas, getMovimientos, canDelete}) => {

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} className="modal_carga">
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sx={{ p: 0, mb: 1, pr: 1 }}
              className="center_item"
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Contenedores
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{overflowY: 'scroll', maxHeight: 500}}
            >
              {data.map((item) => (
                <>
                  {
                    item?.master !== 'individual' &&
                    <Grid container spacing={0} sx={{border: "1px solid #A9A9A9", borderRadius: 2, width: '100% !important', p:1, mb:2}}>
                    <Grid
                    item
                    xs={6}
                    className="center_item"
                    sx={{justifyContent: 'flex-start'}}
                    >
                      <span style={{fontWeight: 600}}>
                        {item?.master === 'individual' ? 'Contenedores individuales' : `Etiqueta master: ${item?.master}`}
                      </span>
                    </Grid>
                    <Grid
                    item
                    xs={6}
                    className="center_item"
                    sx={{justifyContent: 'flex-end'}}
                    >
                      <span style={{fontWeight: 600}}>
                        Cantidad: {item?.cantidad_total}
                      </span>
                    </Grid>
                    {item?.items?.map((paq) => (
                      <Grid
                          item
                          xs={6}
                          sm={6}
                          md={4}
                          lg={4}
                          xl={4}
                          sx={{
                            p: 1,
                            textAlign: "start",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Chip
                            label={
                              <div
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: 7,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color:"black",
                                  }}
                                >
                                  {paq?.cantidad}
                                </span>
                                <span
                                  style={{
                                    marginLeft: 7,
                                    border: "1px solid #575E3B",
                                    borderRadius: 4,
                                    fontSize: 11,
                                    padding: 3,
                                    color: "#575E3B",
                                  }}
                                >
                                  {paq?.tag}
                                </span>
                              </div>
                            }
                            variant="outlined"
                            sx={{
                              height: 40,
                              borderRadius: 2,
                              display: "flex",
                              justifyContent:
                              item?.master === 'individual' && canDelete
                                  ? "flex-end"
                                  : "center",
                              width: "100%",
                              backgroundColor:"transparent",
                            }}
                        />
                      </Grid>
                    ))}
                    </Grid>
                  }
                </>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  )
}