import React, { useContext } from "react";
import { ThemeContext } from "@emotion/react";
import { Grid, InputAdornment, TextField } from "@mui/material";
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import { GroupingTable } from "../../../../components/Table/grouping_table";

const TablaGeneral = ({sample, filtro, handleInputChange, onPressEnter}) => {

    const theme = useContext(ThemeContext);
    const navigate = useNavigate();
		const sort = {}

    const columns =  [
			{
				accessorKey: 'part_nombre',
				header: 'Nombre',
			}, 
			{
				accessorKey: 'part_codigoentrada',
				header: 'Código de entrada',
			},
			{
				accessorFn: (row) => row.empresas?.emp_razonsocial || '',
				id: 'cliente',
				header: 'Cliente',
				sortingFn: 'text',
				filterFn: 'includesString',
			},
			{
				accessorKey: 'part_estatus',
				header: 'Estatus',
				Cell: ({ cell }) => (cell.getValue() === 1 ? 'Activo' : 'Inactivo'),
			},
    ]
    const rows = sample;

    const redirect = (row) => {
      if(row){
				navigate(`/inventarios/partes/${row?.part_id}`);
			}  
    }


    return(
        <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{p:1, justifyContent: 'flex-end'}} className="center_item">
                <TextField
                id="search_parte"
                label="Ingrese palabra clave"
                name="search_parte"
                size="small"
                value={filtro}
                onChange={handleInputChange}
                onKeyDown={onPressEnter}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Search sx={{fontSize:20}}/>
                    </InputAdornment>
                    ),
                }}
                sx={{ color: theme.palette.primary.input }}
                />
            </Grid>
          </Grid>
					<GroupingTable key='table_partes' columnas={columns} data={rows} redirect={redirect} sort={sort}/>
        </Paper>
    )
}

export default TablaGeneral;