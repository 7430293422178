import { Paper } from "@mui/material";
import moment from "moment";
import React from "react";
import { GroupingTable } from "../../../../components/Table/grouping_table";


export const TablaEnviados = ({sample, setOrden}) => {
  const sort = { id: 'docu_folio', desc: true }
  const rows = sample;
  const columns = [
    {
        accessorKey: 'docu_folio',
        header: 'Folio',
    }, 
    {
      accessorFn: (row) => moment(row?.docu_fecharegistro, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY") || '',
      id: 'fecha',
      header: 'Fecha',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
        accessorFn: (row) => row.empresas?.emp_razonsocial || '',
        id: 'cliente',
        header: 'Cliente',
        sortingFn: 'text',
        filterFn: 'includesString',
    }
  ];

  const redirect = (row) => {
    if(row){
      setOrden(row)
    }
  }


  return(
    <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
      <GroupingTable  
      key='tabla_salidas_enviadas' 
      columnas={columns} 
      data={rows} 
      redirect={redirect}
      sort={sort}
      />
    </Paper>
  )
}