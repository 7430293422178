import { Grid, InputAdornment, Paper, TextField } from "@mui/material";
import { GroupingTable } from "../../../components/Table/grouping_table";
import { Search } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "@emotion/react";
import moment from "moment";
import { TipoDocumentoEnum } from "../../../@enums/tipo_documento";
import { GetOrdenesNew } from "../../../hooks/getOrdenes";
import { EstatusDocumentoEnum } from "../../../@enums/status_documento.enum";

export const TablaAjustesActivos = ({create, setCreate}) => {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const { getOrdenesNewMutation } = GetOrdenesNew();
  const [rows, setRows] = useState([]);
  const sort = {}
  const columns = [
    {
      accessorKey: 'docu_folio',
      header: 'Folio',
    }, 
    {
      accessorFn: (row) => moment(row?.docu_fecharegistro, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY") || '',
      id: 'fecha',
      header: 'Fecha',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      accessorFn: (row) => JSON.parse(row?.docu_extradata).almacen || '',
      id: 'almacen',
      header: 'Almacen',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
  ];

  const redirect = (row) => {
    if(row){
      navigate(`/inventarios/ajuste-inventario/${row?.docu_id}`);
    }
  }

  //Obtener data
  const get_ordenes_activas = () => {
    let where = {
      docu_tipo: TipoDocumentoEnum.AJUSTE,
      docu_estatus: EstatusDocumentoEnum.SIN_ESTATUS,
    };
    let relations = { empresas: true };
    getOrdenesNewMutation(
      { where: where, relations: relations, clave: '' },
      {
        onSuccess: (data) => {
          setRows(data);
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  };
  useEffect(() => {
    get_ordenes_activas();
  }, []);
  useEffect(() => {
    if (create) {
      get_ordenes_activas();
      setCreate(false);
    }
  }, [create]);
  
  return(
    <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
      <GroupingTable  
      key='tabla_ajustes_activas' 
      columnas={columns} 
      data={rows} 
      redirect={redirect}
      sort={sort}
      />
    </Paper>
  )
}