import { Grid, InputAdornment, Paper, TextField } from "@mui/material";
import { GroupingTable } from "../../../components/Table/grouping_table";
import { Search } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "@emotion/react";
import moment from "moment";
import { useLoading } from "../../../components/Loading/useLoading";
import { TipoDocumentoEnum } from "../../../@enums/tipo_documento";
import { GetOrdenesNew } from "../../../hooks/getOrdenes";
import { EstatusDocumentoEnum } from "../../../@enums/status_documento.enum";

export const TablaSalidaInventario = ({getSend, setGetSend}) => {
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const {setAppLoading} = useLoading();
  const { getOrdenesNewMutation } = GetOrdenesNew();
  const [rows, setRows] = useState([]);
  const [filtro, setFiltro] = useState('');
  const sort = {}
  
  const columns = [
    {
      accessorKey: 'docu_folio',
      header: 'Folio',
    }, 
    {
      accessorFn: (row) => moment(row?.docu_fecharegistro, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY") || '',
      id: 'fecha',
      header: 'Fecha',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
    {
      accessorFn: (row) => JSON.parse(row?.docu_extradata).almacen || '',
      id: 'almacen',
      header: 'Almacen',
      sortingFn: 'text',
      filterFn: 'includesString',
    },
  ];
  const redirect = (row) => {
    if(row){
      navigate(`/inventarios/salida-inventario/${row?.docu_id}`);
    }
  }

  //Search
  const handleInputChange = (event) => {
    setFiltro(event.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      get_ordenes_enviadas()
    }
  };

  const get_ordenes_enviadas = () => {
    let where = {
      docu_tipo: TipoDocumentoEnum.SALIDA_ALMACEN,
      docu_estatus: EstatusDocumentoEnum.CERRADO,
    };
    setAppLoading(true);
    getOrdenesNewMutation(
      { where: where, relations: {}, clave: filtro},
      {
        onSuccess: (data) => {
          setRows(data);
          setAppLoading(false)
        },
        onError: (error) => {
          console.log(error);
          setAppLoading(false);
        },
      }
    );
  };

  useEffect(() => {
      if (getSend) {
        setRows([]);
        setGetSend(false);
      }
  }, [getSend]);
  
  return(
    <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={{p:1, justifyContent: 'flex-end'}} className="center_item">
          <TextField
          id="search_entry"
          label="Ingrese palabra clave"
          name="search_entry"
          size="small"
          value={filtro}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          InputProps={{
              startAdornment: (
              <InputAdornment position="start">
                  <Search sx={{fontSize:20}}/>
              </InputAdornment>
              ),
          }}
          sx={{ color: theme.palette.primary.input }}
          />
        </Grid>
      </Grid>
      <GroupingTable  
      key='tabla_salida_inventario' 
      columnas={columns} 
      data={rows} 
      redirect={redirect}
      sort={sort}
      />
    </Paper>
  )
}