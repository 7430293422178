import { Alert, Autocomplete, Box, Button, FormControl, FormHelperText, Grid, TextField, Typography } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { TipoDocumentoEnum } from "../../../@enums/tipo_documento"
import { EstatusDocumentoEnum } from "../../../@enums/status_documento.enum"
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer"
import { useDispatch, useSelector } from "react-redux"
import { useLoading } from "../../../components/Loading/useLoading"
import { ThemeContext } from "@emotion/react"
import { GetAllClientesList } from "../../../hooks/getListClientes"
import swal from 'sweetalert2'
import { GetAllPartesList } from "../../../hooks/getPartesList"
import { GetOrdenCliente } from "../../../hooks/getListOrdenCliente"
import { RecursosEnum } from "../../../@enums/tipo_images.enum"
import { Add, PhotoLibrary } from "@mui/icons-material"
import CarouselImg from "../../../components/carousel"
import { TipoSplitEnum } from "../../../@enums/tipo_split"
import { ItemDevolucion } from "./itemDevolucion"
import { TiposAlmacenEnum } from "../../../@enums/tipo_almacen"
import { GetAllAlmacenesList } from "../../../hooks/getListAlmacen"
import moment from "moment"
import { EnviarDevolucion, GetTagDevolucion } from "../../../hooks/devolucion"
import { TicketLocal } from "../../../hooks/sendPrint"
import { useNavigate } from "react-router-dom"
import { useSnackbar } from "notistack"
import { ModalCausasDevolucion } from "./components/modalCausas"
import { GetCausasDevolucionesByTipo } from "../../../hooks/causa_devolucion"


export const NuevaDevolucion = () => {

  const printerId = useSelector((state) => state.app.printerId);
  const theme = useContext(ThemeContext);
  const formData = useRef(null);
  const navigate = useNavigate();
  const {setAppLoading} = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {getAllClientesListMutation} = GetAllClientesList();
  const { getAllPartesListMutation } = GetAllPartesList();
  const { getCausasDevolucionesByTipoMutation } = GetCausasDevolucionesByTipo();
  const { getOrdenClienteMutation } = GetOrdenCliente();
  const { getAllAlmacenesListMutation } = GetAllAlmacenesList();
  const { enviarDevolucionMutation } = EnviarDevolucion();
  const { getTagDevolucionMutation } = GetTagDevolucion();
  const { ticketLocalMutation } = TicketLocal();
  const [clientes, setClientes] = useState([]);
  const [clienteSelect, setClienteSelect] = useState(null);
  const [parteSelect, setParteSelect] = useState(null);
  const [partes, setPartes] = useState([]);
  const [partesFilter, setPartesFilter] = useState([]);
  const [ordenes, setOrdenes] = useState([]);
  const [errEmp, setErrEmp] = useState(false);
  const [errPart, setErrPart] = useState(false);
  const [errCantidad, setErrCant] = useState(false);
  const [errOrd, setErrOrd] = useState(false);
  const [errSplit, setErrSplit] = useState(false);
  const [errExist, setErrExist] = useState(false);
  const [imagenes, setImagenes] = useState([]);
  const [almacenes, setAlmacenes] = useState([]);
  const [headData, setHeadData] = useState(
    {
      tag: '',
      emp_id: null,
      part_id: null,
      cantidad: null,
      docu_tipo: TipoDocumentoEnum.DEVOLUCION,
      docu_estatus: EstatusDocumentoEnum.SIN_ESTATUS,
      orden: null,
      docu_fecha: moment().format('YYYY-MM-DD HH:mm:ss') 
    }
  )
  const [existencias, setExistencias] = useState(null);
  const [arraySplit, setArraySplit] = useState([]);
  const [causas, setCausas] = useState([]);
  const [openModalCausas, setOpenModalCausas] = useState(false);

  useEffect(() => {
    dispatch(
        HEADER_DATA_UPDATE({nombreData: 'Nueva Devolución'})
    )
  }, []);
  useEffect(() => {
      get_clientes();
      get_partes();
      get_almacenes();
      get_causas();
  }, []);
  

  //ETIQUETA

  const get_tag = (code) => {
    setAppLoading(true);
    getTagDevolucionMutation(code, {
      onSuccess: (data) => {
        if(data){
          let parts = partes.filter((part) => part.emp_id === data?.emp_id);
          let part = partes.find((item) => item.value === parseInt(data.part_id));
          setImagenes(part?.recursos);
          setPartesFilter(parts);
          get_ordenes(data?.emp_id);
          // Crear una copia del estado antes de actualizarlo
          setHeadData(prev => ({
            ...prev,
            emp_id: data?.emp_id,
            part_id: data?.part_id,
            cantidad: data?.cantidad,
            orden: null,
            tag: code
          }));
          setExistencias(data?.cantidad);
          setArraySplit([])
          setErrCant(false);
          setErrEmp(false);
          setErrPart(false);
          add_split(data?.cantidad)
          
        }else{
          enqueueSnackbar( "Etiqueta Invalida", {
						autoHideDuration: 2000,
						variant: "error",
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
					});
        }
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error);
        setAppLoading(false);
      }
    })
  }

  const get_data_tag = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      if(headData?.emp_id !== null){
        swal.fire({
          title: "¿Esta seguro que desea buscar datos por etiqueta?",
          text: "Perderá todos los movimientos registrados",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí, buscar"
        }).then((result) => {
          if (result.isConfirmed) {
            get_tag(event.target.value)
          }
        });
      }else{
        get_tag(event.target.value);
      }
      setAppLoading(false)
    }
  }

  //CLIENTES
  const get_clientes = () => {
    getAllClientesListMutation({},{
        onSuccess: (data) => {
            data.sort((a, b) => {
              return a.label.localeCompare(b.label);
            })
            setClientes(data)
        },
        onError: (error) => {
            console.log(error)
        }
    })
  }
  const changeClient = (id) => {
    if(headData.emp_id !== null){
        swal.fire({
            title: "¿Esta seguro que desea cambiar de empresa?",
            text: "Perderá todos los movimientos registrados",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, cambiar"
        }).then((result) => {
            if (result.isConfirmed) {
              if(id !== null){
                setHeadData({
                  ...headData, 
                  emp_id: id.value, 
                  part_id: null,
                  orden: null,
                  tag: null
                });
                setClienteSelect(id);
                let parts = partes.filter((part) => part.emp_id === id.value);
                setPartesFilter(parts);
                get_ordenes(id.value)
              }else{
                setHeadData({
                  ...headData, 
                  emp_id: null, 
                  part_id: null,
                  orden: null,
                  tag: null
                })
                setClienteSelect(null);
                setPartesFilter([]);
                setOrdenes([])
              }
              setParteSelect(null);
              setImagenes([]);
            }
        });
    }else{
      if(id !== null){
        setAppLoading(true);
        setHeadData({...headData, emp_id: id.value})
        let parts = partes.filter((part) => part.emp_id === id.value);
        setPartesFilter(parts);
        setClienteSelect(id);
        get_ordenes(id.value)
        
      }else{
        setHeadData({...headData, emp_id: null});
        setPartesFilter([]);
        setClienteSelect(null);
        setOrdenes([])
      }
    }
    setAppLoading(false)
  }
  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
    }
  };
  //PARTES
  const get_partes = () => {
    setAppLoading(true);
    getAllPartesListMutation(
      {},
      {
        onSuccess: (data) => {
          setPartes(data);
          setAppLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setAppLoading(false);
        },
      }
    );
  };
  const changePart = (id) => {
    if(id){
      let part = partes.find((item) => item.value === parseInt(id.value));
      setParteSelect(part);
      setHeadData({...headData, part_id: part?.value, tag: null })
      setImagenes(part?.recursos);
      setErrPart(false);
    }else{
      setParteSelect(null);
      setImagenes([]);
      setErrPart(true);
      setHeadData({...headData, part_id: null, tag: null })
    }
    
  };
  //ORDENES
  const get_ordenes = (cliente) => {
    setAppLoading(true);
    getOrdenClienteMutation(cliente, {
      onSuccess: (data) => {
        console.log(data)
        setOrdenes(data);
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error);
        setAppLoading(false);
      },
    });
  };
  //ALMACENES
  const get_almacenes = () => {
    setAppLoading(true)
    getAllAlmacenesListMutation(
      {},
      {
        onSuccess: (data) => {
          let itms = data.filter(item => item.tipo === TiposAlmacenEnum.ALMACEN);
          setAlmacenes(itms);
          setAppLoading(false)
        },
        onError: (error) => {
          setAppLoading(false)
          console.log(error);
        },
      }
    );
  };

  //SPLIT
  const add_split = (total = null) => {
    console.log(total)
    let new_split = {
      cantidad: !total ? existencias : total,
      tipo: TipoSplitEnum.REGULAR,
      alma_id: null,
      causa: null,
      containers: []
    }
    setExistencias(0)
    setArraySplit([...arraySplit, new_split])
  }

  //CAUSAS_DEVOLUCION
  const get_causas = () => {
    getCausasDevolucionesByTipoMutation(TipoDocumentoEnum.DEVOLUCION, {
      onSuccess: (data) => {
        setCausas(data)
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }

  //EMVIO
  const validate_data = (e) => {
    e.preventDefault();

    const newErrors = {
      errEmp: !headData.emp_id,
      errCantidad: !headData.cantidad || headData.cantidad < 1,
      errOrd: !headData.orden,
      errPart: !headData.part_id
    };

    // Actualizar estado de errores
    setErrEmp(!headData.emp_id);
    setErrCant(!headData.cantidad || headData.cantidad < 1);
    setErrOrd(!headData.orden);
    setErrPart(!headData.part_id);

    // Validaciones de arraySplit
    const errArraySplit =
    arraySplit.length === 0 ||
    arraySplit.some(
      (item) => !item.alma_id || 
      !item.cantidad || 
      item.cantidad < 1 || 
      item.containers.length === 0 ||
      item.containers.reduce((acumulador, numero) => acumulador + numero, 0) !== item.cantidad
    );

    setErrSplit(errArraySplit);
    
    if(!errArraySplit && !Object.values(newErrors).some(error => error)){
      let data = {
        ...headData,
        printer_id: printerId,
        split: arraySplit
      }
      send_data(data)
    }
  }
  const send_data = (body) => {
    console.log(body)
    setAppLoading(true);
    enviarDevolucionMutation(body, {
      onSuccess: (data) => {
        if(data.success){
          if(data.data.length > 0){
            for(let print of data?.data){
              ticketLocalMutation(print, {
                  onSuccess: (data) => {
                      console.log(data)
                  },
                  onError: (error) => {
                      console.log(error)
                  }
              })
            }
          }
          
          
        }
        setAppLoading(false);
        navigate(`/inventarios/devoluciones`);
      },
      onError: (error) => {
        setAppLoading(false);
        console.log(error)
      }
    })
  }

  return(
    <>
      <form ref={formData} id="form_dom" style={{ width: "100%" }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center', mb:2}}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
                Datos Devolución
            </Typography>
            <Button variant="contained" className="btn_system" onClick={validate_data} sx={{width:150, ml:1}}>
                Guardar
            </Button>                
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={9} xl={9} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={6} lg={2} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <TextField
                  id='dev_tag'
                  fullWidth
                  size="small"
                  name="dev_tag"
                  label="Etiqueta"
                  defaultValue={null}
                  sx={{color: theme.palette.primary.input}}
                  onKeyDown={(e)=>get_data_tag(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={4} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <FormControl fullWidth  size="small" error={errEmp}>
                  <Autocomplete
                    fullWidth
                    id="emp_id"
                    key={`emp_${headData?.emp_id}`}
                    getOptionLabel={(opcion) => opcion.label}
                    filterSelectedOptions
                    options={clientes}
                    onKeyDown={handleKeyUp}
                    value={clientes?.find(o => o?.value === headData?.emp_id)}
                    onChange={(e, newValue)=>{
                        if(newValue === null){
                          changeClient(null)
                        }else{
                          changeClient(newValue)
                        }
                    }}
                    renderOption={(props, option)=> (
                        <Box {...props} key={option?.value} sx={{width: '100%'}}>{option.label}</Box>
                    )}
                    renderInput={(params) => 
                        <TextField {...params} name="emp_id" label="Cliente" size="small" fullWidth  required/>
                    }
                  />
                  <FormHelperText>
                      {errEmp ? "Seleccione un cliente" : ""}
                    </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={4} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <FormControl fullWidth size="small" error={errPart}>
                  <Autocomplete
                    fullWidth
                    id="part_id"
                    key={`part_${headData?.part_id}`}
                    getOptionLabel={(opcion) => 
                      opcion?.codigo !== undefined ?
                      `${opcion?.label} / ${opcion?.codigo} / ${opcion?.codigo_salida} / ${opcion?.codigo_alterno}` :
                      opcion?.label
                    }
                    filterSelectedOptions
                    options={partesFilter}
                    onKeyDown={handleKeyUp}
                    value={partesFilter?.find(o => o?.value === headData.part_id)}
                    onChange={(e, newValue)=>{
                        if(newValue === null){
                          changePart('')
                        }else{
                          changePart(newValue)
                        }
                    }}
                    renderOption={(props, option)=> (
                        <Box {...props} key={option?.value}>
                          {option?.codigo !== undefined ? option?.codigo : <></>} / 
                          {option?.codigo_salida !== undefined ? option?.codigo_salida : <></>} / 
                          {option?.codigo_alterno !== undefined ? option?.codigo_alterno : <></>} / {option?.label}
                        </Box>
                    )}
                    renderInput={(params) => 
                        <TextField {...params} label="Parte" size="small" fullWidth  required/>
                    }
                    />
                    <FormHelperText>
                      {errPart ? "Seleccione una parte" : ""}
                    </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={2} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <FormControl fullWidth size="small" error={errCantidad}>
                  <TextField
                    id='dev_cant'
                    name="dev_cant"
                    key={`cant_${headData?.emp_id}`}
                    size="small"
                    type="number"
                    fullWidth
                    hiddenLabel
                    required
                    label="Cantidad"
                    value={headData.cantidad}
                    sx={{color: theme.palette.primary.input}}
                    onChange={(e)=>{
                      if(e.target.value === '' || parseInt(e.target.value) < 1 ){
                        setHeadData({...headData, cantidad: null, tag: null});
                        setExistencias(null)
                        setErrCant(true);
                      }else{
                        setHeadData({...headData, cantidad: parseInt(e.target.value), tag: null});
                        setExistencias(parseInt(e.target.value))
                        setErrCant(false);
                      }
                      setArraySplit([]);
                    }}
                    className="input-date"
                  />
                  <FormHelperText>
                    {errCantidad ? "Ingrese una cantidad válida" : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={2} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
                <FormControl fullWidth size="small" error={errOrd}>
                  <Autocomplete
                    fullWidth
                    id="orden"
                    name="orden"
                    key={`ord_${headData?.orden}`}
                    getOptionLabel={(opcion) => opcion?.label}
                    filterSelectedOptions
                    options={ordenes}
                    onKeyDown={handleKeyUp}
                    value={ordenes?.find(o => o?.value === headData.orden)}
                    onChange={(e, newValue)=>{
                      console.log(newValue)
                        if(newValue === null){
                          setHeadData({...headData, orden: newValue })
                        }else{
                          setHeadData({...headData, orden: newValue?.value })
                        }
                    }}
                    renderOption={(props, option)=> (
                      <Box {...props} key={option?.value}>{option.label}</Box>
                    )}
                    renderInput={(params) => 
                        <TextField {...params} label="Orden de compra" size="small" fullWidth  required/>
                    }
                    />
                    <FormHelperText>
                      {errOrd ? "Seleccione una orden" : ""}
                    </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:'0px 50px', textAlign:'start', display:'flex', justifyContent:'center', alignItems:'center'}}>
            {
              imagenes?.filter
              ((res) => +res?.recu_tipo === RecursosEnum.PRINCIPAL)
              .length === 0 ?
              (
                <PhotoLibrary 
                sx={{
                  color: '#575E3B',
                  fontSize: 80
                }}
                />
              ) : 
              (
                <CarouselImg
                key={"carousel"}
                recursos={imagenes?.filter(
                  (res) => +res?.recu_tipo === RecursosEnum.PRINCIPAL
                )}
                />
              )
            }
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          {
            errSplit && <Alert severity="error" style={{textAlign: "center", display: "flex", justifyContent: "center", width: '100%'}}>Verifique sus datos para los contenedores</Alert>
          }
          <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center', mb:2}}>
            <Button 
            variant="contained" 
            className="btn_system" 
            onClick={()=>add_split()}
            disabled = {
              headData.cantidad === null 
            }
            sx={{width:'auto', minWidth: '8rem', ml:1}}>
                <Add />
            </Button>                
          </Grid>
          {
            arraySplit.map((split, index) => (
              <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center', mb:2}}>
                <ItemDevolucion
                key={`dev_${index}`}
                form_data={formData}
                array_split={arraySplit}
                existencia={existencias}
                index={index}
                setExistencia={setExistencias}
                set_array_split={setArraySplit}
                data={split}
                almacenes={almacenes}
                update={false} 
                causas_dev={causas}
                setOpen={setOpenModalCausas}/>
              </Grid>
            ))
          }
        </Grid>
      </form>
      <ModalCausasDevolucion 
      key='modal_causas' 
      open={openModalCausas} 
      setOpen={setOpenModalCausas}
      refresh_causas={get_causas}
      />
    </>
  )
}