import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetCertificadosPanel = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/administracion-inventario/certificado/panel/${params.etiqueta}/${params.almacen}`, {
          responseType: 'blob', // Indicamos que esperamos un archivo binario (blob)
        });
        return data;
    };

    const mutation = useMutation(mutate);

    return {
        getCertificadosPanelMutation: mutation.mutate,
    };
}