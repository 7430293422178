import { useRef, useState } from "react"
import { Button, Chip, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material"
import { exportToExcel } from 'react-json-to-excel';


export const RequestFormTrazabilidad = ({search, sampleExcel}) => {

  const inputRef = useRef(null);
  const [data, setData] = useState(null);
  const [type, setType] = useState(1);
  const [serialArray, setSerialArray] = useState([]);


  const handleChange = (event) => {
    setType(parseInt(event.target.value));
  };

  const addSerialToSearch = (e) => {
    if(e.key === "Enter" && e.target.value !== ''){
      let serials = [...serialArray];
      let exist = serials.some(item => item === e.target.value);
      if(!exist){
        serials.push(e.target.value);
        setSerialArray([...serials])
      }
      inputRef.current.value = "";
    }
  }

  const deleteSerial = (index) => {
    let array = serialArray;
    array.splice(index, 1);
    setSerialArray([...array]);
  }

  const sumbmitFiltro = () => {
    let body = {
      codigo: type === 1 ? data : serialArray,
      tipo: type
    }
    search(body);
    setSerialArray([])
  }

  return(
    <Grid container spacing={0} sx={{mt:2}}>
      <Grid item xs={6} sm={6} md={3} lg={3} xl={3} className="center_item">
        <TextField
          id="search_map"
          label="Ingrese etiqueta o serial"
          name="search_map"
          size="small"
          fullWidth
          inputRef={inputRef}
          onChange={(e)=>setData(e.target.value)}
          onKeyDown={
            (e) => 
              type === 2 ? 
              addSerialToSearch(e):null
          }
        />
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={2} xl={2} className="center_item">
        <RadioGroup
          row
          aria-labelledby="serial/tag"
          name="serial/tag"
          value={type}
          onChange={handleChange}
        >
          <FormControlLabel value={1} control={<Radio />} label="Etiqueta" />
          <FormControlLabel value={2} control={<Radio />} label="Serial" />
        </RadioGroup>
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={2} xl={2} className="center_item" sx={{p:'0 5px'}}>
        <Button 
        variant="contained" 
        className="btn_system" 
        onClick={sumbmitFiltro}
      >
        Buscar
      </Button>
      </Grid>
      <Grid item xs={6} sm={6} md={3} lg={2} xl={2} className="center_item" sx={{p:'0 5px'}}>
        <Button 
        variant="contained" 
        className="btn_system" 
        sx={{backgroundColor: '#00B050'}}
        disabled={!sampleExcel}
        onClick={
          () => exportToExcel(
            sampleExcel, 
            `galus_trazabilidad_${type === 1 ? 'etiqueta': 'serial'}_${data}`
          )
        }
      >
        Exportar .xlsx
      </Button>
      </Grid>
      <Grid key={`seriales_size_${serialArray.length}`} item xs={12}  sx={{display:'flex', flexDirection: 'row', mt:2}}>
        {
          serialArray.map((serial, index) => (
            <Chip 
            key={`chip_${index}`} 
            label={serial} 
            onDelete={()=>deleteSerial(index)} 
            className="chip_tag_traz"
            sx={{
              width: 'auto', 
              backgroundColor: '#575E3B', 
              color: 'white', 
              ml:1
            }}
            />
          ))
        }
      </Grid>
    </Grid>
  )
}