import { Routes, Route} from "react-router-dom";
import Home from "../views/home/home";
import { ProtectedRoutes } from "./private_routes";
import InactivityTimer from "../components/timerActivity";
import { AUTH_LOGOUT } from "../redux/reducers/auth.reducer";
import swal from "sweetalert2";
import { useDispatch } from "react-redux";

const HomeRoutes = () => {

    const dispatch = useDispatch();

    const onInactive = () => {
		console.log('entra')
		swal
		.fire({
			position: "center",
			icon: "warning",
			title: "Tiempo de sesión expirado por inactividad",
			showConfirmButton: false,
			timer: 10000,
		})
		.then(() => {
			dispatch(
				AUTH_LOGOUT()
			);
		});
	}

    return(
        <>
            <Routes>
                <Route element={<ProtectedRoutes    />}>
                    <Route  key='login_default' path='/' element={<Home />}/>
                    <Route  key='login' path='/home' element={<Home />}/>
                </Route>
            </Routes>
            <InactivityTimer key='inactivity_component' onInactive={onInactive}/>
        </>
    )
}

export default HomeRoutes;