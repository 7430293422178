import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const GetEntity = ()  => {
    const api = useApi();

    const mutate = async (param) => {
        const data  = await api.get(`/query-builder/all`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getEntityMutation: mutation.mutate,
    };
}

export const BuildQuery = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/query-builder`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        buildQueryMutation: mutation.mutate,
    };
}

export const GetReportesList = ()  => {
    const api = useApi();

    const mutate = async (param) => {
        const data  = await api.get(`/reportes/all`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getReportesListMutation: mutation.mutate,
    };
}

export const GetReporte = ()  => {
    const api = useApi();

    const mutate = async (param) => {
        const data  = await api.get(`/reportes?repo_id=${param}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getReporteMutation: mutation.mutate,
    };
}

export const CrearReporte = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/reportes`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        crearReporteMutation: mutation.mutate,
    };
}