import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const TicketLocal = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.ticketLocalPost(params);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        ticketLocalMutation: mutation.mutate,
    };
}