import { ThemeContext } from "@emotion/react";
import { Grid, InputAdornment, Paper, TextField } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Search } from "@mui/icons-material";
import { GroupingTable } from "../../../components/Table/grouping_table";

const TablaEntradas = ({sample, filtro, handleInputChange, onPressEnter}) => {

    const theme = useContext(ThemeContext);
    const navigate = useNavigate();
		const sort = {}

		const tipos_entrada = [
			{
					id: 0,
					label: "Sin Estatus"
			},
			{
					id: 1,
					label: "Cancelado"
			},
			{
					id: 2,
					label: "Cobrado"
			},
			{
					id: 3,
					label: "Timbrado"
			},
		]
    const rows = sample;
    const columns = [
			{
				accessorKey: 'docu_folio',
				header: 'Folio',
			}, 
			{
				accessorFn: (row) => moment(row?.docu_fecha, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY") || '',
				id: 'fecha',
				header: 'Fecha',
				sortingFn: 'text',
				filterFn: 'includesString',
			},
			{
				accessorKey: 'docu_serie',
				header: 'Serie',
			},
			{
				accessorFn: (row) => row.empresas?.emp_razonsocial || '',
				id: 'cliente',
				header: 'Cliente',
				sortingFn: 'text',
				filterFn: 'includesString',
			},
			{
				accessorFn: (row) => tipos_entrada.find(alm => alm.id === row.docu_estatus)?.label  || '',
				id: 'estatus',
				header: 'Estatus',
				sortingFn: 'text',
				filterFn: 'includesString',
			},
    ];

    const redirect = (row) => {
			if(row){
        navigate(`/inventarios/entradas/${row?.docu_id}`);
			}
    }

    return(
        <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{p:1, justifyContent: 'flex-end'}} className="center_item">
                <TextField
                id="search_entry"
                label="Ingrese palabra clave"
                name="search_entry"
                size="small"
                value={filtro}
                onChange={handleInputChange}
                onKeyDown={onPressEnter}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Search sx={{fontSize:20}}/>
                    </InputAdornment>
                    ),
                }}
                sx={{ color: theme.palette.primary.input }}
                />
            </Grid>
          </Grid>
					<GroupingTable  
          key='tabla_entradas' 
          columnas={columns} 
          data={rows} 
          redirect={redirect}
					sort={sort}
          />
        </Paper>
    )
}

export default TablaEntradas;