import { Autocomplete, Box, Button, Divider, FormControl, Grid, TextField } from "@mui/material"
import { useDispatch } from "react-redux";
import { useContext, useEffect, useRef, useState } from "react";
import { TipoDocumentoEnum } from "../../../@enums/tipo_documento";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { useLoading } from "../../../components/Loading/useLoading";
import { CrearOrdenNew } from "../../../hooks/crearOrden";
import { EstatusDocumentoEnum } from "../../../@enums/status_documento.enum";
import { GetAllAlmacenesList } from "../../../hooks/getListAlmacen";
import { TiposAlmacenEnum } from "../../../@enums/tipo_almacen";
import { useSnackbar } from "notistack";
import { ThemeContext } from "@emotion/react";
import { AddTag } from "../../../hooks/ajuste";
import { TablaSalidaInventarioActivos } from "./tablaSalidaInventarioActivos";
import { TablaSalidaInventario } from "./tablaSalidaInventario";


export const SalidaInventarioIndex = () => {

  const dispatch = useDispatch();
  const {setAppLoading} = useLoading();
  const theme = useContext(ThemeContext);
  const { enqueueSnackbar } = useSnackbar();
  const {crearOrdenNewMutation} = CrearOrdenNew();
  const {addTagMutation} = AddTag();
  const { getAllAlmacenesListMutation } = GetAllAlmacenesList();
  const [seccionId, setSeccionId] = useState(1);
  const [almacenes, setAlmacenes] = useState([]);
  const [almacen, setAlmacen] = useState(null);
  const [isCreate, setIsCreate] = useState(false);
  const [getSend, setGetSend] = useState(false);
  const inputRef = useRef(null);


  const btn_seccion = [
    {
      id: 1,
      name: "Abiertos",
      component: (
        <TablaSalidaInventarioActivos
          key={"tabla_activos"}
          create={isCreate}
          setCreate={setIsCreate}
        />
      ),
    },
    {
      id: 2,
      name: "Cerrados",
      component: (
        <TablaSalidaInventario
          key={"tabla_cerrados"}
          getSend={getSend}
          setGetSend={setGetSend}
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      HEADER_DATA_UPDATE({nombreData: null})
    )
  }, []);

  //ALMACENES
  const get_almacenes = () => {
    setAppLoading(true)
    getAllAlmacenesListMutation(
      {},
      {
        onSuccess: (data) => {
          let itms = data.filter(item => item.tipo === TiposAlmacenEnum.ALMACEN);
          console.log(itms)
          setAlmacenes(itms);
          setAppLoading(false)
        },
        onError: (error) => {
          setAppLoading(false)
          console.log(error);
        },
      }
    );
  };
  useEffect(() => {
    get_almacenes();
  }, []);

  //SECCION
  const changeSeccion = (id) => {
		id === 1 ? setIsCreate(true) : setGetSend(true);
		setSeccionId(id);
	};

  //CREAR ORDEN
  const create_new_orden = () => {
    if(!almacen) return;
    let data = {
      docu_tipo: TipoDocumentoEnum.SALIDA_ALMACEN,
      docu_folio: null,
      docu_estatus: EstatusDocumentoEnum.SIN_ESTATUS,
      alma_id: JSON.stringify({almacen:almacen?.label, alma_id:almacen?.id})
    }
    setAppLoading(true)
    crearOrdenNewMutation({dataDocumento: data, arrayDocMov: []},{
      onSuccess: (data) => {
        setAppLoading(false)
        if(data?.data?.id_doc === 0){
          enqueueSnackbar("Ya existe una orden abierta para este almacén", {
            autoHideDuration: 2000,
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
          return null;
        }
        setIsCreate(true);
      },
      onError: (error) => {
        console.log(error);
        setAppLoading(false)
      }
    })
  }

  //AÑADIR ETIQUETA
  const add_tag = (tag) => {
    setAppLoading(true);
    inputRef.current.blur();
    addTagMutation({tag, docu_tipo: TipoDocumentoEnum.SALIDA_ALMACEN}, {
      onSuccess: (data) => {
        inputRef.current.value = "";
        setAppLoading(false);
        enqueueSnackbar(data?.mensaje, {
          autoHideDuration: 2000,
          variant: data?.success ? "success" : "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        inputRef.current.focus();
      },
      onError: (error) => {
        setAppLoading(false);
        inputRef.current.value = "";
				inputRef.current.focus();
        enqueueSnackbar("Hubo un error al procesar, inténtelo nuevamente", {
          autoHideDuration: 2000,
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      }
    })
  }

  return(
    <>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          sx={{ p: 2 }}
          className="center_item"
        >
          <FormControl fullWidth size="small">
            <Autocomplete
              fullWidth
              id="alm_id"
              getOptionLabel={(opcion) => opcion.label}
              filterSelectedOptions
              options={almacenes}
              value={almacen}
              onChange={(e, newValue)=>{
                  if(newValue === null){
                    setAlmacen(null)
                  }else{
                    setAlmacen(newValue)
                  }
              }}
              renderOption={(props, option)=> (
                  <Box {...props} key={option?.id}>{option.label}</Box>
              )}
              renderInput={(params) => 
                  <TextField {...params} label="Almacén" size="small" fullWidth  required/>
              }
              />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          sx={{ p: 2 }}
          className="center_item"
        >
          <Button variant="contained" className="btn_system" onClick={create_new_orden}>
            Abrir Salida
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={0} sx={{height:'80vh', display: 'flex', justifyContent: 'space-between'}} >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={ seccionId === 2 ? 8 : 4 }
          xl={ seccionId === 2 ? 8 : 4 }
          sx={{ p: 1 }}
          className="center_item"
        >
          <Grid container spacing={0}>
            {btn_seccion.map((btn) => (
              <Grid
                key={btn.name}
                item
                xs={seccionId === 2 ? 2 : 6}
                sx={{ p: 1 }}
                className="center_item"
              >
                <Button
                  variant="contained"
                  className="btn_square"
                  key={btn.id}
                  sx={
                    seccionId === btn.id
                      ? {
                          color: theme.palette.primary.contrastText,
                          bg: theme.palette.primary.main,
                          fontSize: 12,
                        }
                      : {
                          color: theme.palette.primary.title,
                          bgcolor: theme.palette.secondary.btn,
                          fontSize: 12,
                        }
                  }
                  onClick={() => changeSeccion(btn.id)}
                  disableElevation
                >
                  {btn.name}
                </Button>
              </Grid>
            ))}
          </Grid>
          
        </Grid>
        {
          seccionId === 1 &&
          <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
          sx={{ p: 2, gap: 2 }}
          className="center_item"
        >
          <TextField
            id="tag"
            label="Etiqueta"
            fullWidth
            size="small"
            inputRef={inputRef}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                add_tag(e.target.value);
              }
            }}
            sx={{ color: theme.palette.primary.input }}
          />
          </Grid>
        }
        <Grid item xs={12} sx={{ p: 1, height: "75vh" }} className="center_item">
          {btn_seccion[seccionId - 1]?.component}
        </Grid>
      </Grid>
    </>
  )
}