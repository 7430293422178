import { Button, Divider, Grid } from "@mui/material"
import { useDispatch } from "react-redux";
import { useLoading } from "../../../components/Loading/useLoading";
import { useEffect } from "react";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { useNavigate } from "react-router-dom";
import { TablaQuerys } from "./tablaQuerys";


export const QueryIndex = () => {

	const dispatch = useDispatch();
	const {setAppLoading} = useLoading();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(
			HEADER_DATA_UPDATE({nombreData: null})
		)
	}, []);


	const go_create = () => {
		navigate(`/reportes/personalizado/nuevo`);
	}

	return(
		<>
			<Grid container spacing={0} sx={{height:'10vh'}}>
					<Grid item xs={3} sm={3} md={3} lg={2} xl={2} sx={{padding:'10px 30px', height:'100%'}} className="center_item">
						<Button 
						variant="contained" 
						className="btn_system" 
						onClick={() => go_create(true)}>
							Nuevo
						</Button>
					</Grid>
			</Grid>
			<Divider />
			<Grid container spacing={0} sx={{height:'70vh'}}>
				<Grid item xs={12} sx={{padding:'10px 20px', height:'100%'}} className="center_item">
					<TablaQuerys	key='tabla_querys' />
				</Grid>
			</Grid>
		</>
	)
}