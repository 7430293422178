import { Alert, Button, Grid, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { GetAllAlmacenesList } from "../../../../hooks/getListAlmacen";
import { TipoSplitEnum } from "../../../../@enums/tipo_split";
import { Add, ChevronLeft, ChevronRight, PhotoLibrary } from "@mui/icons-material";
import { AreaAlmacenEnum, TiposAlmacenEnum } from "../../../../@enums/tipo_almacen";
import ItemReport from "./itemReport";
import { ReportarMovimiento } from "../../../../hooks/reportMovimiento";
import swal from "sweetalert2";
import { GetRutaParte } from "../../../../hooks/getRutasParte";
import { useSelector } from "react-redux";
import { useLoading } from "../../../../components/Loading/useLoading";
import { GetAyudasVisuales } from "../../../../hooks/getAyudasVisuales";
import 'swiper/css';
import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { TicketLocal } from "../../../../hooks/sendPrint";

const Reportar = ({movimiento, setMovReporting, setSaveReport, saveReport, setIsReporting, seccionId, getMovimientos, get_Cargados, get_procesados, alm}) => {
  const {setAppLoading} = useLoading();
  const printerId = useSelector((state) => state.app.printerId);
  const formData = useRef(null);
  const { getAllAlmacenesListMutation } = GetAllAlmacenesList();
  const { reportarMovimientoMutation } = ReportarMovimiento();
  const { getAyudasVisualesMutation } = GetAyudasVisuales();
  const { ticketLocalMutation } = TicketLocal();
  const { getRutaParteMutation } = GetRutaParte();
  const [almacenes, setAlmacenes] = useState([]);
  const [indexSelect, setIndexSelect] = useState(null);
  const [error, setError] = useState(false);
  const [cuestionario, setCuestionario] = useState(null);
  const [parteFueraDeRuta, setParteFueraDeRuta] = useState(false);
  const [ayudasVisuales, setAyudasVisuales] = useState([]);
  const [rutas, setRutas] = useState([]);
  const [autoMaster, setAutoMaster] = useState(true);
  const { apiResources } = useSelector((state) => ({
    apiResources: state.app.apiResources,
  }));

  const [arraySplit, setArraySplit] = useState([{moin_id: movimiento?.datos[0]?.moin_id, cantidad:movimiento?.existencia_total, split: [movimiento?.existencia_total], ok:true, almacen: movimiento?.almacen, tipoSplit: TipoSplitEnum.REGULAR, idCert: cuestionario, respuestas:[], part_id:movimiento?.part_id, serial: movimiento?.datos[0]?.serial, tag_id:movimiento?.datos[0]?.tag_id, almacen_actual: seccionId, master:false}])
  
  const get_almacenes = () => {
    setAppLoading(true)
    getAllAlmacenesListMutation(
      {},
      {
        onSuccess: (data) => {
          let itms = data.filter(item => item.tipo === TiposAlmacenEnum.ALMACEN);
          let almacen_siguiente = itms.find(almacen => almacen.id === arraySplit[0].almacen);
          if(almacen_siguiente.area === AreaAlmacenEnum.CUARENTENA){
            arraySplit[0].master = false;
            arraySplit[0].idCert = null;
          }
          setAlmacenes(itms);
          setAppLoading(false)
        },
        onError: (error) => {
          setAppLoading(false)
          console.log(error);
        },
      }
    );
  };

  const get_cuestionario = () => {
    getRutaParteMutation(movimiento?.part_id, {
      onSuccess: (data) => {
        let array = arraySplit;
        let rutaIndex = data.findIndex(ruta => ruta?.alma_id === seccionId);
        let ruta_act = data.find(ruta => ruta?.alma_id === seccionId);
        setRutas(data)
        if(ruta_act !== undefined){
          let cues = null
          if(rutaIndex === 0){
            // si se encuentra al inicio de la ruta se toma el certificado de traspaso
            cues =  ruta_act?.cues_reporteoid;
            array[0].idCert = cues;
          }else {
            // si no se encuentra al inicio de la ruta se toma el certificado de E/S
            if(ruta_act?.rupr_revisionentradasalida){
              cues =  ruta_act?.cues_id;
              array[0].idCert = cues;
            }
          }
          array.map(item => item.master = ruta_act?.rupr_generamasterreporteo);
          setAutoMaster(ruta_act?.rupr_generamasterreporteo)
          setArraySplit([...array]);
          setCuestionario(cues);
        }else{
          array[0].idCert = null;
          setArraySplit([...array]);
          setCuestionario(null);
        }

        if(!ruta_act){
          setParteFueraDeRuta(true)
        } else {
          setParteFueraDeRuta(false)
        }
        
        
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }
  const getResources = (id) => {
    setAppLoading(true);
    getAyudasVisualesMutation(id, {
      onSuccess: (data) => {
        setAyudasVisuales(data);
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error);
        setAppLoading(false);
      },
    });
  };
  
  useEffect(() => {
    get_almacenes();
    get_cuestionario();
    let mov = movimiento;
    movimiento.existencia_total = 0;
    setMovReporting({...mov})
    getResources(mov?.part_id)
  }, []);

  useEffect(() => {
    if(saveReport){
      saveData()
    }
  }, [saveReport]);

  const validate = () => {
    let emptyCant = arraySplit.some(item => item.cantidad === null);
    let emptyAlm = arraySplit.some(item => item.almacen === null);
    let emptySplit = arraySplit.some(item => item.split.length === 0);
    let emptyResponse =  arraySplit.some(item => item.idCert !== null && item.respuestas.length === 0);
    if(emptyAlm || emptyCant || emptySplit || emptyResponse ){
      setError(true)
      return false
    }else{
      setError(false);
      return true
    }
  }

  const saveData = () => {
    if(validate()){
      setAppLoading(true);  
      let data = arraySplit;
      data.forEach(item => {
        item.respuestas.forEach(res => {
          res.cure_respuesta = Array.isArray(res?.cure_respuesta)
          ? JSON.stringify(res?.cure_respuesta)
          : res?.cure_respuesta;
        })
      })
      // reportarMovimientoMutation({printerId, almacenOrigen: alm, movimientos:data},{
      //   onSuccess: (data) => {
      //     swal.fire({
      //       position: "top-end",
      //       icon: "success",
      //       title: "Movimiento reportado con éxito",
      //       showConfirmButton: false,
      //       timer: 1000,
      //     })
      //     for(let print of data?.data){
      //       ticketLocalMutation(print, {
      //           onSuccess: (data) => {
      //               console.log(data)
      //           },
      //           onError: (error) => {
      //               console.log(error)
      //           }
      //       })
      //   }
      //     setSaveReport(false);
      //     setIsReporting(false);
      //     setMovReporting(null);
      //     get_Cargados(seccionId);
      //   get_procesados(seccionId);
      //   getMovimientos(seccionId);
      //   setAppLoading(false);
      //   },
      //   onError: (error) => {
      //     setSaveReport(false);
      //     swal.fire({
      //       position: "top-end",
      //       icon: "error",
      //       title: error?.mensaje,
      //       showConfirmButton: false,
      //       timer: 1000,
      //     });
      //     setAppLoading(false);
      //   }
      // })
      reportarMovimientoMutation(
        { printerId, almacenOrigen: alm, movimientos: data },
        {
          onSuccess: async (data) => { // <-- Hacemos onSuccess async
            swal.fire({
              position: "top-end",
              icon: "success",
              title: "Movimiento reportado con éxito",
              showConfirmButton: false,
              timer: 1000,
            });
      
            try {
              for (let print of data?.data) {
                await new Promise((resolve, reject) => {
                  ticketLocalMutation(print, {
                    onSuccess: (response) => {
                      console.log(response);
                      resolve(); // Solo avanza cuando se complete esta petición
                    },
                    onError: (error) => {
                      console.log(error);
                      reject(error); // Detiene la secuencia si hay error
                    },
                  });
                });
              }
            } catch (error) {
              console.error("Error en el proceso de impresión:", error);
            }
      
            setSaveReport(false);
            setIsReporting(false);
            setMovReporting(null);
            get_Cargados(seccionId);
            get_procesados(seccionId);
            getMovimientos(seccionId);
            setAppLoading(false);
          },
          onError: (error) => {
            setSaveReport(false);
            swal.fire({
              position: "top-end",
              icon: "error",
              title: error?.mensaje,
              showConfirmButton: false,
              timer: 1000,
            });
            setAppLoading(false);
          },
        }
      );
      
    }else{
      setSaveReport(false)
    }
    
  }

  const addData = () => {
    let new_data = { moin_id: movimiento?.datos[0]?.moin_id, cantidad:null, split: [], ok:true, almacen: movimiento?.almacen, tipoSplit: TipoSplitEnum.REGULAR, idCert: cuestionario, respuestas:[], part_id:movimiento?.part_id, serial: movimiento?.datos[0]?.serial, tag_id:movimiento?.datos[0]?.tag_id, almacen_actual: seccionId, master:autoMaster}
    setArraySplit([...arraySplit, new_data])
  }

  return (
    <>
      <Grid item xs={12} sx={{p:1}} className="center_item">
        <form ref={formData} id="form_dom" style={{ width: "100%" }}>
          {
            parteFueraDeRuta && <Alert severity="warning" style={{textAlign: "center", display: "flex", justifyContent: "center"}}>No. de parte fuera de ruta</Alert>
          }
          <Grid container spacing={0} sx={{width:'100%'}}>
            <Grid item xs={12} sm={12} md={4} lg={2} xl={2} sx={{p:1, alignItems:'flex-end'}} className="center_item">
              <Button 
                variant="contained" 
                sx={{mr:1, p:1, width:'100% !important', minWidth:'20px', height:35}}  
                onClick={addData}
                disabled={movimiento?.existencia_total === 0}
                >
                <Add />
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={5} xl={ayudasVisuales.length > 1 ? 5 : 6} sx={{p:1, alignItems: 'flex-end'}} className="center_item">
              {error && <p style={{color:'red'}}>Existen datos por completar, favor de verificar</p>}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={5} xl={ayudasVisuales.length > 1 ? 5 : 4} sx={{p:1}} className="center_item">
              {
                ayudasVisuales.length === 0 && (
                  <PhotoLibrary 
                  sx={{
                    color: '#575E3B',
                    fontSize: 80
                  }}
                  />
                )
              }
              {
                ayudasVisuales.length !== 0 && (
                    <Grid container spacing={0} sx={{width:'100%', display: 'flex', alignItems: 'center'}}>
                      {
                        ayudasVisuales.length === 1 && (
                          <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                            <img 
                              src={`${apiResources}${ayudasVisuales[0]?.recu_ruta}`} 
                              alt={ayudasVisuales[0]?.recu_nombre} 
                              style={{
                                width: 180,
                                height: 100,
                              }}/>
                          </div>
                        )
                      }
                      {
                        ayudasVisuales.length > 1 && (
                          <Grid item xs={12} sx={{p:1, alignItems: 'center'}} className="center_item">
                            <IconButton
                              aria-label="open drawer"
                              edge="start"
                              className="swiper-button-prev"
                              sx={{
                                  color:'#575E3B',
                                  marginRight: 1,
                                  fontWeight: 600,
                                  fontSize: 20,
                                  mt:7
                              }}
                            >
                                <ChevronLeft sx={{fontSize: 35}} />
                            </IconButton> 
                            <Swiper
                              modules={[Navigation, Pagination, Scrollbar, A11y]}
                              spaceBetween={10}
                              slidesPerView={
                                ayudasVisuales.length > 4 ? 3 : 2
                              }
                              navigation={{
                                prevEl: '.swiper-button-prev', // Conecta con tus botones personalizados
                                nextEl: '.swiper-button-next',
                              }}
                              loop={true}
                              style={{
                                maxWidth: '450px'
                              }}
                            >
                              {
                              ayudasVisuales.map((image, index) => (
                                <SwiperSlide>
                                  <img 
                                    src={`${apiResources}${image?.recu_ruta}`} 
                                    alt={image?.recu_nombre} 
                                    style={{
                                      width: ayudasVisuales.length > 4 ? 150 : '100%',
                                      height: ayudasVisuales.length > 4 ? 100 : 125,
                                    }}/>
                                </SwiperSlide>
                              ))
                              }
                            </Swiper>
                            <IconButton
                              aria-label="open drawer"
                              edge="start"
                              className="swiper-button-next"
                              sx={{
                                  color:'#575E3B',
                                  marginLeft: 1,
                                  fontWeight: 600,
                                  fontSize: 20,
                                  mt:7
                              }}
                            >
                                <ChevronRight sx={{fontSize: 35}} />
                            </IconButton> 
                          </Grid>
                        )
                      }
                    </Grid>
                  
                )
              }
            </Grid>
          </Grid>
          {
            arraySplit?.map((item, index) => (
              <ItemReport 
              key={`enc_${index}`} 
              almacenes={almacenes} 
              arraySplit={arraySplit} 
              formData={formData} 
              index={index} 
              item={item} 
              movimiento={movimiento} 
              setArraySplit={setArraySplit} 
              setIndexSelect={setIndexSelect} 
              setMovReporting={setMovReporting} 
              exist_question={item.idCert !== null} 
              cuestionario={cuestionario} 
              autoMaster={autoMaster}
              />
            ))
          }
        </form>
      </Grid>
    </>
  )
}

export default Reportar;