import { ThemeContext } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../../../components/Loading/useLoading";
import { Grid, InputAdornment, Paper, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { GroupingTable } from "../../../components/Table/grouping_table";
import moment from "moment";
import { GetReportesList } from "../../../hooks/reporteQuery";


export const TablaQuerys = () => {

  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const {setAppLoading} = useLoading();
  const { getReportesListMutation } = GetReportesList();
  const [rows, setRows] = useState([]);
  const [filtro, setFiltro] = useState('');
  const sort = {}

  const columns = [
    {
      accessorKey: 'repo_nombre',
      header: 'Nombre',
    }, 
    {
      accessorKey: 'repo_desc',
      header: 'Descripción',
    }, 
    {
      accessorFn: (row) => moment(row?.repo_fecha, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY") || '',
      id: 'fecha',
      header: 'Fecha',
      sortingFn: 'text',
      filterFn: 'includesString',
    }
  ];

  const redirect = (row) => {
    if(row){
      navigate(`/reportes/personalizado/visual/${row?.repo_id}`);
    }
  }

  const get_reportes_enviados = () => {
    setAppLoading(true)
    getReportesListMutation({},{
      onSuccess: (data) => {
        setRows(data.data);
        setAppLoading(false);
      },
      onError: (error) => {
        console.log(error);
        setAppLoading(false);
      }
    })
  };

  useEffect(() => {
    get_reportes_enviados();
  }, []);

  return(
    <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
      <GroupingTable  
      key='tabla_reportes_querys' 
      columnas={columns} 
      data={rows} 
      redirect={redirect}
      sort={sort}
      />
    </Paper>
  )
}