import { Autocomplete, Box, Button, FormControl, Grid, IconButton, MenuItem, Paper, Select, Tab, TextField } from "@mui/material"
import { GroupingTable } from "../../../components/Table/grouping_table"
import { useEffect, useState } from "react"
import { BuildQuery, GetReporte } from "../../../hooks/reporteQuery";
import { useLoading } from "../../../components/Loading/useLoading";
import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { useParams } from "react-router-dom";
import moment from "moment";
import { exportToExcel } from "react-json-to-excel";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSnackbar } from "notistack";
import { Cancel, Delete } from "@mui/icons-material";


export const ReporteVisual = () => {

	const { id } = useParams();
	const dispatch = useDispatch();
	const {setAppLoading} = useLoading();
  const { enqueueSnackbar } = useSnackbar();
	const { getReporteMutation } = GetReporte();
	const { buildQueryMutation } = BuildQuery();
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);
	const [fields, setFields] = useState(null);
  const [fieldOptions, setFieldOptions] = useState(null);
  const [conditionsFields, setConditionsFields] = useState([]);
  const [tab, setTab] = useState(1);
	const sort = {};


  //SELECT OPERATORS
  const operators = {
    numeric: ["=", "!=", ">", "<", ">=", "<="],
    string: ["=", "!=", "LIKE"],
    boolean: ["=", "!="],
    date: ["=", "!=", ">", "<", ">=", "<="],
  };
  const numericTypes = ["int", "double", "float", "decimal"];
  const stringTypes = ["varchar", "text", "char"];
  const dateTypes = ["date", "datetime", "timestamp"];
  const booleanTypes = ["bool", "boolean", "tinyint(1)"];

  const getOperatorsByType = (field_type) => {

    if (numericTypes.includes(field_type?.toLowerCase())) return operators.numeric;
    if (stringTypes.includes(field_type?.toLowerCase())) return operators.string;
    if (dateTypes.includes(field_type?.toLowerCase())) return operators.date;
    if (booleanTypes.includes(field_type?.toLowerCase())) return operators.boolean;

    // Default a solo igualdad y desigualdad si no reconoce el tipo
    return ["=", "!="];
  };


	useEffect(() => {
		get_data_report();
	}, []);

	const isDateString = (value) => {
		return typeof value === "string" && /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/.test(value);
	};

	const generateColumns = (data) => {
		if (!data.length) return [];
	
		return Object.keys(data[0]).map((key) => {
			const sampleValue = data[0][key];
	
			if (isDateString(sampleValue)) {
				return {
					accessorFn: (row) =>
						moment(row[key], "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY") || "",
					id: key,
					header: key.replace(/_/g, " ").toUpperCase(),
					sortingFn: "text",
					filterFn: "includesString",
				};
			}
	
			return {
				accessorKey: key,
				header: key.replace(/_/g, " ").toUpperCase(),
			};
		});
	};
	
	const build_query = (fields) => {
		setAppLoading(true);
		buildQueryMutation(fields, {
			onSuccess: (data) => {
				console.log(data);
				if(data.success){
					let cols = generateColumns(data?.data);
					setColumns(cols)
					setRows(data?.data)
				}
				setAppLoading(false);
			},
			onError: (error) => {
				console.log(error);
				setAppLoading(false);
			}
		})
	}

	const get_data_report = () => {
		setAppLoading(true);
		getReporteMutation(id, {
			onSuccess: (data) => {
				if(data){
					if(data.success){
						let reporte = data?.data;
						let fields  = JSON.parse(data?.data?.repo_fields);
						console.log(fields)
						dispatch(
							HEADER_DATA_UPDATE({nombreData: `Reporte personalizado ${reporte?.repo_id}`})
						)
            let options = fields.report_fields.filter(item => item?.type === 'field');
						build_query(fields);
						setFields(fields);
            setFieldOptions(options)
						setAppLoading(false);
					}
				}
			},
			onError: (error) => {
				console.log(error);
				setAppLoading(false);
			}
		})
	}

  //TAB
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
    }
  };

  const changeCondition = (grupo, index, value, type) => {
    let conditions = [...conditionsFields];
    switch (type) {
      case 1:
        if(value){
          conditions[grupo][index].origin = value?.field_origin;
          conditions[grupo][index].field = value?.field;
          conditions[grupo][index].data_type = value?.fiel_type;
          conditions[grupo][index].alias = value?.alias;
        }else{
          conditions[grupo][index].origin = null;
          conditions[grupo][index].field = null;
        }
        break;
      case 2:
        conditions[grupo][index].operator = value
        break;
      case 3:
        conditions[grupo][index].value = value
        break;
    
      default:
        break;
    }
    
    setConditionsFields(conditions)
  }

  const add_filtro = (grupo) => {
    let conditions = [...conditionsFields]
    let data = {
      type: "field",
      origin: null,
      field: null,
      operator: null,
      value:null,
      data_type: null,
      alias: null
    }
    conditions[grupo].push(data);
    setConditionsFields(conditions)
  }

  const rebuild_query = () => {
    let body = {...fields};
    let not_exist_null =  conditionsFields.every(subArray => 
      subArray.every(obj => 
          obj.type !== null &&
          obj.origin !== null &&
          obj.field !== null &&
          obj.operator !== null &&
          obj.value !== null &&
          obj.data_type !== null
      )
    );
    if(!not_exist_null){
      enqueueSnackbar( 'Existen filtros incompletos', {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }else{
      body.whereConditions = [...conditionsFields];
      console.log(conditionsFields)
      setAppLoading(true);
      buildQueryMutation(body, {
        onSuccess: (data) => {
          console.log(data);
          if(data.success){
            setRows(data?.data);
          }
          setAppLoading(false);
          enqueueSnackbar( 'Datos actualizados', {
            autoHideDuration: 2000,
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
        },
        onError: (error) => {
          console.log(error);
          setAppLoading(false);
          enqueueSnackbar( 'Hubo un error al procesar', {
            autoHideDuration: 2000,
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
        }
      })
    }
    
  }

  const delete_filtro = (grupo, index) => {
    let conditions = [...conditionsFields];
    conditions[grupo].splice(index, 1);
    setConditionsFields([...conditions])
  }
  const delete_group = (grupo) => {
    let conditions = [...conditionsFields];
    conditions.splice(grupo, 1);
    setConditionsFields([...conditions])
  }

  return(
		<div style={{width: '93vw'}}>
			<Grid container spacing={0} >
				<Grid item xs={8} sx={{p:1}} className="center_item">
					<Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
						<GroupingTable  
						key={`tabla_reportes_visual_${columns.length}`} 
						columnas={columns} 
						data={rows}
						sort={sort}
            redirect={()=>{}}
						/>
					</Paper>
				</Grid>
				<Grid item xs={4} sx={{p:1, justifyContent: 'flex-start', flexDirection: 'column'}} className="center_item">
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', textAlign: 'center' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label='Filtros' value={1} />
                <Tab label='acciones' value={2} />
              </TabList>
            </Box>
            <TabPanel value={1} sx={{width: '100%', p:0}}>
              <Button 
                variant="contained" 
                className="btn_system"
                fullWidth
                onClick={rebuild_query}
                sx={{mt:2}}
                >
                Aplicar filtros
              </Button>
              <Button 
                variant="outlined" 
                className="btn_system"
                fullWidth
                onClick={()=>setConditionsFields([...conditionsFields, []])}
                sx={{mt:2}}
                >
                + Nuevo grupo
              </Button>
              {conditionsFields?.map((group, index_group) => (
                <>
                  <div style={{width: '100%', border:'1px solid #575E3B', borderRadius: 12, display:'flex', flexDirection: 'column', padding:5, marginTop:15}}>
                    <Grid container spacing={0} sx={{display: 'flex', justifyContent:'space-between'}} >
                      <Grid item xs={4} sx={{p:1}} className="center_item">
                        <Button 
                        variant="contained" 
                        className="btn_system"
                        fullWidth
                        onClick={()=>add_filtro(index_group)}
                        >
                        + Nuevo filtro
                        </Button>
                      </Grid>
                      <Grid item xs={3} sx={{p:1, justifyContent: 'flex-end'}} className="center_item">
                        <IconButton 
                          aria-label="delete"
                          onClick={()=> delete_group(index_group)}
                          >
                          <Cancel />
                        </IconButton>
                      </Grid>
                    </Grid>
                    {group?.map((cond, index_cond) => (
                      <Grid container spacing={0} >
                        <Grid item xs={5} sx={{p:1}} className="center_item">
                          <FormControl size="small" fullWidth>
                            <Autocomplete
                            fullWidth
                            id={`item_column_${index_cond}_g${index_group}`}
                            getOptionLabel={(opcion) => opcion?.alias ? `${opcion.field}(${opcion.alias})` : opcion.field}
                            filterSelectedOptions
                            options={fieldOptions}
                            onKeyDown={handleKeyUp}
                            value={cond !== null ? fieldOptions.find(item => item?.field === cond?.field) : null}
                            onChange={(e, newValue)=>changeCondition(index_group, index_cond, newValue, 1)}
                            renderOption={(props, option)=> (
                                <Box {...props} key={`${index_group}_${index_cond}_${option?.field}`}>{`${option?.field} ${option?.alias ? `(${option.alias})` : ''}`}</Box>
                            )}
                            renderInput={(params) => 
                                <TextField {...params} label="Campo" size="small" fullWidth  required/>
                            }
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2} sx={{p:1}} className="center_item">
                          <Select
                            key={index_cond}
                            labelId={`operator-select-${index_group}-${index_cond}`}
                            id={`operator-select-${index_group}-${index_cond}`}
                            name={`operator-select-${index_group}-${index_cond}`}
                            value={cond?.operator}
                            onChange={(e) => changeCondition(index_group, index_cond, e.target.value, 2)}
                            displayEmpty
                          >
                            {getOperatorsByType(cond?.data_type).map((op) => (
                                <MenuItem key={op} value={op}>{op}</MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={4} sx={{p:1}} className="center_item">
                          {
                            booleanTypes.some(item => item === cond?.data_type) ? (
                              <Select
                              key={index_cond}
                              labelId={`item_value_${index_cond}_g${index_group}`}
                              id={`item_value_${index_cond}_g${index_group}`}
                              name={`item_value_${index_cond}_g${index_group}`}
                              value={cond?.value}
                              onChange={(e) => changeCondition(index_group, index_cond, e.target.value, 3)}
                              displayEmpty
                              fullWidth
                            >
                              <MenuItem key={`true-${index_group}-${index_cond}`} value={true}>True</MenuItem>
                              <MenuItem key={`false-${index_group}-${index_cond}`} value={false}>False</MenuItem>
                              </Select>
                            ) : (
                              <TextField
                                id={`item_value_${index_cond}_g${index_group}`}
                                type={
                                  dateTypes.some(item => item === cond.data_type) ? "date": 
                                  numericTypes.some(item => item === cond.data_type) ? "number" :
                                  "text"
                                }
                                fullWidth
                                size="small"
                                value={cond?.value}
                                name={`item_value_${index_cond}_g${index_group}`}
                                hiddenLabel
                                placeholder="Valor referencia"
                                onChange={(e) => changeCondition(index_group, index_cond, e.target.value, 3)}
                                className="input-date"
                              />
                            )
                          }
                          
                        </Grid>
                        <Grid item xs={1} sx={{p:1}} className="center_item">
                          <IconButton 
                          aria-label="delete"
                          onClick={()=> delete_filtro(index_group, index_cond)}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                </>
              ))}
            </TabPanel>
            <TabPanel value={2}>
              <Button 
              variant="contained" 
              className="btn_system" 
              sx={{backgroundColor: '#00B050'}}
              disabled={rows.length === 0}
              onClick={
                () => exportToExcel(
                  rows, 
                  `galus_reporte_personalizado_${id}`
                )
              }
            >
              Exportar .xlsx
              </Button>
            </TabPanel>

          </TabContext>
				</Grid>
			</Grid>
		</div>
	)
}