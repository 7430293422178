export const  TipoDocumentoEnum  = Object.freeze({
    ENTRADA: 1,
    TRASPASO: 2,
    SALIDA: 3,
    RECEPCION: 4,
    ORDEN_COMPRA: 5,
    ORDEN_ENTREGA: 6,
    DEVOLUCION: 7,
    AJUSTE:8,
    SALIDA_ALMACEN:9
})