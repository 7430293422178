import { ThemeContext } from "@emotion/react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Box, Button, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import React, { useContext, useState } from "react";
import { ReporteIndividual } from "../../../../hooks/reportes";
import { GetAyudasVisuales } from "../../../../hooks/getAyudasVisuales";
import { useLoading } from "../../../../components/Loading/useLoading";


export const TablaReportesAlmacen = ({sample, where, setAyudasVisuales, setOpenAyudas}) => {

  const { getAyudasVisualesMutation } = GetAyudasVisuales();
  const {setAppLoading} = useLoading()
  const theme = useContext(ThemeContext);

  const VerSerial = ({data}) => {
    const seriales = data;

    const Title = () => {

      return (
        <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', flexDirection:'column'}}>
          {seriales.map(item => (
            <span>{item}</span>
          ))}
        </div>
      )
    }

    return(
      <Tooltip title={<Title />} placement="top" arrow>
        <Button variant="text">Ver Seriales</Button>
      </Tooltip>
    )
  }

  const Row = (props) => {
    const { row, almacen, nombre_almacen } = props;
    const [open, setOpen] = useState(false);
    const {reporteIndividualMutation} = ReporteIndividual();
    const [movimientos, setMovimientos] = useState([]);

    const get_movimientos = (almacen, part_id) => {
      let body = {
        almacen,
        status: where?.status,
        part_id,
        fecha_inicio: where?.fecha_inicio,
        fecha_fin: where?.fecha_fin,
        export: false
      }
      reporteIndividualMutation(body,{
        onSuccess: (data) => {
          if(data?.success){
            setMovimientos(data?.data)
          }
        },
        onError: (error) => {
          console.log(error)
        }
      })
    }
    const open_subtabla = (almacen, id_parte) => {
      if(open){
        setOpen(!open);
        setMovimientos([]);
      }else{
        get_movimientos(almacen, id_parte)
        setOpen(!open);
      }
    }
    const getResources = (id) => {
      setAppLoading(true);
      getAyudasVisualesMutation(id, {
        onSuccess: (data) => {
          setAyudasVisuales(data);
          setOpenAyudas(true);
          setAppLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setAppLoading(false);
        },
      });
    };

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell sx={{p:1}}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => open_subtabla(almacen, row?.part_id)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
            onClick={
              () => getResources(
                row["part_id"]
              )
            }
          >
            {row.codigo}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
            onClick={
              () => getResources(
                row["part_id"]
              )
            }
          >
            {row.nombre}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
            onClick={
              () => getResources(
                row["part_id"]
              )
            }
          >
            {row.existencia_total}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
            onClick={
              () => getResources(
                row["part_id"]
              )
            }
          >
            {row.cliente}
          </TableCell>
          <TableCell
            align="center"
            sx={{
              display: "table-cell",
              fontWeight: 500,
              color: theme.palette.primary.input,
              background: "white",
              p:1
            }}
            onClick={
              () => getResources(
                row["part_id"]
              )
            }
          >
            {nombre_almacen}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, marginBottom:10 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Desgloce de contenedores
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Serial</TableCell>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Etiqueta</TableCell>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Etiqueta master</TableCell>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Cantidad</TableCell>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Fecha reporteo</TableCell>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Fecha impresión</TableCell>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Usuario impresión</TableCell>
                        <TableCell align="center" sx={{color: theme.palette.primary.main, fontSize:16}}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {movimientos?.map((mov) => (
                        <TableRow key={mov?.moin_id}>
                          <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.moin_serial.length === 1 ? mov?.moin_serial[0]: <VerSerial  key={`serial_${mov?.moin_id}`} data={mov?.moin_serial}/>}</TableCell>
                          <TableCell align="center" sx={{pl: 1.8, pr:1.8, pt:1, pb:1}}>{mov?.etiq_codigo}</TableCell>
                          <TableCell align="center" sx={{pl: 1.8, pr:1.8, pt:1, pb:1}}>{mov?.etiq_master}</TableCell>
                          <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.moin_cantidad}</TableCell>
                          <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.moin_fechareportado ? mov?.moin_fechareportado : null}</TableCell>
                          <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.etiq_fechaimpresion ? mov?.etiq_fechaimpresion : null}</TableCell>
                          <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.user}</TableCell>
                          <TableCell align="center" sx={{pl: 0, pr:0, pt:1, pb:1}}>{mov?.status}</TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }


  return(
    <>
    <Paper  sx={{ boxShadow: "none", mb:2, width: '100%'}}>
      <TableContainer component={Paper} sx={{mt:3}}>
        <Table aria-label="collapsible table">
          <TableHead
            sx={{
              display: "table-head",
              backgroundColor: theme.palette.primary.main,
              fontWeight: 600,
            }}
          >
            <TableRow>
              <TableCell />
              <TableCell align="center" sx={{ color: "white" }}>
                Código
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Nombre
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Existencia Total
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Cliente
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                Almacen
              </TableCell>
            </TableRow>
          </TableHead>
          {
            sample?.map((almacen) => (
              <TableBody>
                {almacen?.data?.map((row) => (
                  <Row key={row?.part_id} row={row} almacen={almacen?.alma_id} nombre_almacen={almacen?.almacen}/>
                ))}
              </TableBody>
            ))
          }
        </Table>
      </TableContainer>
    </Paper>
      {/* {
        sample?.map((almacen) => (
          <Accordion 
          key={`data_almacen_${almacen?.almacen}`}
          sx={{width: '100%', mb:2}}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{color:'white'}}/>}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography 
              sx={{
                fontWeight: 600, 
                fontSize:18,
                color: 'white'
              }}>
                {almacen?.almacen}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Paper style={{ height: '100%', width: "100%", marginBottom:10}} sx={{ boxShadow: "none" }}>
                <TableContainer component={Paper} sx={{mt:3}}>
                  <Table aria-label="collapsible table">
                    <TableHead
                      sx={{
                        display: "table-head",
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: 600,
                      }}
                    >
                      <TableRow>
                        <TableCell />
                        <TableCell align="center" sx={{ color: "white" }}>
                          Código
                        </TableCell>
                        <TableCell align="center" sx={{ color: "white" }}>
                          Nombre
                        </TableCell>
                        <TableCell align="center" sx={{ color: "white" }}>
                          Existencia Total
                        </TableCell>
                        <TableCell align="center" sx={{ color: "white" }}>
                          Cliente
                        </TableCell>
                        <TableCell align="center" sx={{ color: "white" }}>
                          Almacen
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {almacen?.data?.map((row) => (
                        <Row key={row?.part_id} row={row} almacen={almacen?.alma_id} nombre_almacen={almacen?.almacen}/>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </AccordionDetails>
          </Accordion>
        ))
      } */}
    </>
  )

}