import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useLoading } from "../../../components/Loading/useLoading";
import { CloseAjuste, GetAjuste, ReactivarEtiqueta, RemoveTagOrderOpen, UndoSalidaInventario } from "../../../hooks/ajuste";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { useEffect, useState } from "react";
import { Button, Chip, Grid, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { EstatusDocumentoEnum } from "../../../@enums/status_documento.enum";
import swal from 'sweetalert2'


export const Ajuste = () => {

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {setAppLoading} = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const {getAjusteMutation} = GetAjuste();
  const {closeAjusteMutation} = CloseAjuste();
  const {reactivarEtiquetaMutation} = ReactivarEtiqueta();
  const {removeTagOrderOpenMutation} = RemoveTagOrderOpen();
    const { undoSalidaInventarioMutation } = UndoSalidaInventario();
  const [ajuste, setAjuste] = useState(null);
  const [isClosed, setIsClosed] = useState(false);


  useEffect(() => {
    get_ajuste();
  }, []);

  const get_ajuste = () => {
    setAppLoading(false)
    getAjusteMutation(id, {
      onSuccess: (data) => {
        if(data){
          dispatch(
            HEADER_DATA_UPDATE({nombreData: `Ajuste Folio ${data?.docu_folio}`})
        )
          data?.docu_estatus === EstatusDocumentoEnum.CERRADO ? 
          setIsClosed(true) : 
          setIsClosed(false);
          setAjuste(data)
        }
      },
      onError: (error) => {
        console.log(error)
      }
    })
  }

  //CERRAR AJUSTE
  const cerrar_ajuste = () => {
    swal.fire({
      title: "¿Esta seguro que desea realizar el ajuste de almacén?",
      text: "Se eliminarán las masters y todos los movimientos que no han sido agregados en el almacén",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, realizar ajuste"
    }).then((result)=> {
      if(result.isConfirmed){
        setAppLoading(true);
        closeAjusteMutation({docu_id: ajuste?.docu_id}, {
          onSuccess: (data) => {
            console.log(data);
            setAppLoading(false);
            enqueueSnackbar( data?.mensaje, {
              autoHideDuration: 2000,
              variant: data?.success ? "success" : "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            });
            navigate(`/inventarios/ajuste-inventario`);
          },
          onError: (error) => {
            console.log(error);
            setAppLoading(false);
          }
        })
      }
    })
  }

  //REACTIVAR ETIQUETA
  const delete_tag = (index, movdoc_id) => {
    swal.fire({
      title: "¿Esta seguro que desea reestablecer esta etiqueta del almacén?",
      text: "Aparecera de nuevo en el almacén y tendra que agregarla a una orden abierta para poder retirarla del almacén nuevamente",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, reestablecer"
    }).then((result)=> {
      if(result.isConfirmed){
        setAppLoading(true);
        reactivarEtiquetaMutation(movdoc_id, {
          onSuccess: (data) => {
            console.log(data);
            if(data?.success){
              let array = ajuste;
              array.documentos_movimientos.splice(index, 1);
              setAjuste(array);
              enqueueSnackbar( 'La etiqueta ha sido reactivada', {
                autoHideDuration: 2000,
                variant: "success",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              });
            }else{
              enqueueSnackbar( 'Ha habido un problema al reactivar', {
                autoHideDuration: 2000,
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              });
            }
            get_ajuste();
            setAppLoading(false);
          },
          onError: (error) => {
            console.log(error);
            setAppLoading(false);
            enqueueSnackbar( 'Ha habido un problema al reactivar', {
              autoHideDuration: 2000,
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            });
          }
        })
      }
    })
    
  }

  //ELIMINAR ETIQUETA ORDEN ABIERTA
  const remove_tag_open = (id) => {
    setAppLoading(true);
    removeTagOrderOpenMutation(id, {
      onSuccess: (data) => {
        enqueueSnackbar( data?.mensaje, {
          autoHideDuration: 2000,
          variant: data?.success ? "success" : "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        setAppLoading(false);
        get_ajuste();
      },
      onError: (error) => {
        enqueueSnackbar( "Hubo un error al procesar", {
          autoHideDuration: 2000,
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        setAppLoading(false);
      }
    })
  }

  //ELIMINAR ajuste COMPLETA
  const undo_ajuste = () => {
    swal.fire({
      title: "¿Esta seguro que desea reestablecer todas las etiquetas de este ajuste?",
      text: "Aparecerán de nuevo en el almacén y tendrá que agregarlas a una orden abierta para poder retirarlas del almacén nuevamente",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, reestablecer"
    }).then((result)=> {
      if(result.isConfirmed){
        setAppLoading(true);
        undoSalidaInventarioMutation(ajuste?.docu_id, {
          onSuccess: (data) => {
            console.log(data);
            if(data?.success){
              enqueueSnackbar( 'Las etiquetas han sido reactivadas', {
                autoHideDuration: 2000,
                variant: "success",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              });
            }else{
              enqueueSnackbar( 'Ha habido un problema al reactivar', {
                autoHideDuration: 2000,
                variant: "error",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              });
            }
            setAppLoading(false);
            navigate(`/inventarios/ajuste-inventario`);
          },
          onError: (error) => {
            console.log(error);
            setAppLoading(false);
            enqueueSnackbar( 'Ha habido un problema al reactivar', {
              autoHideDuration: 2000,
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            });
          }
        })
      }
    })
  }

  return(
    <>
      {
        ajuste && (
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center', mb:2, p:1}}>
                <Typography id="transition-modal-title" variant="h5" component="h2">
                    {`${JSON.parse(ajuste?.docu_extradata)?.almacen?.toUpperCase()}` }
                </Typography>
                {isClosed ? 
                  (
                    <Button variant="contained" className="btn_system" sx={{width:200, ml:1}} onClick={undo_ajuste}>
                      Reestablecer Ajuste
                    </Button>
                  ):
                  (
                    <Button variant="contained" className="btn_system" sx={{width:150, ml:1}} onClick={cerrar_ajuste}>
                      Cerrar Ajuste
                    </Button>
                  )
                }  
            </Grid>
            <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center', mb:2}}>
              <Grid container spacing={0} sx={{border: "1px solid #D9D9D9", borderRadius: 5, mb:3, p:1, display: 'flex'}}>
                <Grid item xs={12} className="container_split" >
                  <Grid
                    container
                    spacing={0}
                    sx={{
                      p: 1,
                      textAlign: "start",
                      display: "flex",
                      alignItems: "center",
                      border: '1px solid #D9D9D9',
                      borderRadius: 2,
                      width: 'auto'
                    }}
                  >
                    {
                      ajuste?.documentos_movimientos.map((doc_mov, index) => (
                        <Grid
                        item
                        xs={6}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                        sx={{
                          p: 1,
                          textAlign: "start",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        >
                          <Chip
                            label={
                              <>
                                <div
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  display: "flex",
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '100%',
                                  maxWidth: '100%'
                                }}
                                >
                                  <span
                                    style={{
                                      marginRight: 7,
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: "black",
                                    }}
                                  >
                                    {JSON.parse(doc_mov?.domo_extradata)?.quantity}
                                  </span>
                                  <span
                                    style={{
                                      marginLeft: 7,
                                      border: "1px solid #575E3B",
                                      borderRadius: 4,
                                      fontSize: 11,
                                      padding: 3,
                                      color: "#575E3B"
                                    }}
                                  >
                                      {JSON.parse(doc_mov?.domo_extradata)?.tag}
                                  </span>
                                </div>
                                <div
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  width: '100%',
                                  maxWidth: '100%',
                                  textAlign: 'center'
                                }}
                                >
                                  <span
                                    style={{
                                      marginLeft: 7,
                                      fontSize: 15,
                                      padding: 3,
                                      color: "black"
                                    }}
                                  >
                                    {`${JSON.parse(doc_mov?.domo_extradata)?.name}`}
                                  </span>
                                  <br/>
                                  <span
                                    style={{
                                      marginLeft: 7,
                                      fontSize: 15,
                                      padding: 3,
                                      color: "black"
                                    }}
                                  >
                                    {`${JSON.parse(doc_mov?.domo_extradata)?.code}`}
                                  </span>
                                </div>
                              </>
                            }
                            variant="outlined"
                            deleteIcon={
                              <Delete sx={{ marginLeft: "1px !important" }} />
                            }
                            onDelete={()=> isClosed ? 
                              delete_tag(index, doc_mov?.domo_id) : 
                              remove_tag_open(doc_mov?.domo_id)
                            }
                            sx={{
                              height: 85,
                              borderRadius: 2,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: 'center',
                              width: "100%",
                              maxWidth: '100%',
                              backgroundColor:"transparent",
                            }}
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    </>
  )
}