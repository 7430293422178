import {
  Grid,
  Input,
  FormLabel,
  Button,
  Radio,
  Snackbar,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ActualizarFormato } from "../../../../hooks/updateFormato";
import { ImprimirEtiqueta } from "../../../../hooks/imprimirEtiqueta";
import swal from 'sweetalert2'
export const EditFormatoForm = ({ defaultData, data, setData }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { actualizarFormato } = ActualizarFormato();
  const { imprimirEtiqueta } = ImprimirEtiqueta();
  const { id } = useParams();
  const handleClose = (event, reason) => {
    setOpen(false);
  };
  const update = (data) => {
    if (data.altura_ancho === "3 x 6") {
      data.foet_height = 6;
      data.foet_width = 3;
    } else if (data.altura_ancho === "3 x 4") {
      data.foet_height = 4;
      data.foet_width = 3;
    }
    delete data.altura_ancho;
    data.id = id;
    data.foet_orientation = "R";
    setUpdating(true);
    actualizarFormato(data, {
      onSuccess: (data) => {
        setOpen(true);
        setUpdating(false);
        setData((prev) => {
          prev.foet_nombre = data?.foet_nombre;
          prev.foet_descripcion = data?.foet_descripcion;
          prev.foet_height = data?.foet_height;
          prev.foet_width = data?.foet_width;
          prev.foet_status = data?.foet_status;
          return prev;
        });
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }
  const onSubmit = (data) => {
    if(defaultData?.foet_status && !data?.foet_status){
      swal.fire({
        title: "¿Esta seguro de inactivar este formato?",
        text: "Si la inactiva no se mostrará en las rutas aunque este en uso",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Inactivar",
      }).then((result) => {
        if (result.isConfirmed) {
          update(data)
        }
      });
    }else{
      update(data)
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      foet_nombre: defaultData.foet_nombre,
      foet_descripcion: defaultData.foet_descripcion,
      foet_height: defaultData.foet_height,
      foet_width: defaultData.foet_width,
      foet_status: defaultData.foet_status
    },
  });

  const foetStatus = watch("foet_status");

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "10px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel component="legend">Nombre</FormLabel>
          <Input
            placeholder="Nombre"
            {...register("foet_nombre", { required: true })}
            error={errors.nombre}
            style={{
              width: "50%",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel component="legend">Descripción</FormLabel>
          <Input
            placeholder="Descripción"
            {...register("foet_descripcion", { required: true })}
            error={errors.descripcion}
            style={{
              width: "50%",
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingLeft: "12px" }}>
          <FormLabel component="legend">Altura y ancho</FormLabel>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              onClick={() => {
                setValue("altura_ancho", "3 x 6");
              }}
              value={"3 x 6"}
              checked={watch("altura_ancho") === "3 x 6"}
            />
            <FormLabel component="legend">3 x 6</FormLabel>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              onClick={() => {
                setValue("altura_ancho", "3 x 4");
              }}
              value={"3 x 4"}
              checked={watch("altura_ancho") === "3 x 4"}
            />
            <FormLabel component="legend">3 x 4</FormLabel>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Radio
              onClick={() => {
                setValue("altura_ancho", "otro");
              }}
              value={"otro"}
              checked={watch("altura_ancho") === "otro"}
            />
            <FormLabel component="legend">Otro</FormLabel>
          </div>
        </Grid>
        {watch("altura_ancho") === "otro" && (
          <>
            <Grid item xs={12}>
              <FormLabel component="legend">Altura</FormLabel>
              <Input
                placeholder="2"
                {...register("foet_height", { required: true })}
                error={errors.descripcion}
                endAdornment="pulgadas"
                style={{
                  width: "50%",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel component="legend">Ancho</FormLabel>
              <Input
                placeholder="2"
                {...register("foet_width", { required: true })}
                error={errors.descripcion}
                endAdornment="pulgadas"
                style={{
                  width: "50%",
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormLabel component="legend">Estado</FormLabel>
          <FormControlLabel
            control={
              <Switch
                {...register("foet_status")}
                checked={foetStatus}
                onChange={(e) => setValue("foet_status", e.target.checked)}
                color="primary"
              />
            }
            label={foetStatus ? "Activo" : "Inactivo"}
          />
        </Grid>
        <Grid item container xs={4}>
          <Grid item xs={4}>
            {updating ? (
              <p>Actualizando...</p>
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Guardar
              </Button>
            )}
          </Grid>
          <Grid item xs={4}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => {
                imprimirEtiqueta(id, {
                  onSuccess: (data) => {
                    console.log(data);
                  },
                  onError: (error) => {
                    console.log(error);
                  },
                });
              }}
            >
              Imprimir etiqueta de prueba
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              type="button"
              variant="ghost"
              color="primary"
              onClick={() => {
                navigate(`/inventarios/etiquetas/items/${id}`);
              }}
            >
              Crear Item
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={open}
        onClose={handleClose}
        message="Cambios guardados"
        autoHideDuration={3600}
      />
    </form>
  );
};
