import { useState } from "react";
import { CreateCausaDevolicion } from "../../../../hooks/causa_devolucion";
import { TipoDocumentoEnum } from "../../../../@enums/tipo_documento";
import { Backdrop, Box, Button, Fade, Grid, Modal, TextField, Typography } from "@mui/material";
import swal from 'sweetalert2'

export const ModalCausasDevolucion = ({open, setOpen, refresh_causas}) => {

  const style = {
      position: 'absolute',
      top: '35%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      display: 'flex',
      justifyContent: 'center'
  };

  const {crearCausaDevolucionMutation} = CreateCausaDevolicion();
  const [errorName, setErrorName] = useState(false);

  const onSubmit = (event) => {
      event.preventDefault()
      if(
          event.target.caus_texto.value !== ''
      ){
          let data = {
              caus_texto: event.target.caus_texto.value,
              caus_tipodocumento: TipoDocumentoEnum.DEVOLUCION
          }
          crearCausaDevolucionMutation(data, {
              onSuccess:(data) => {
                  handleClose()
              },
              onError: (error) => {
                  swal.fire({
                      position: "top-end",
                      icon: "error",
                      title: "Fallo al crear unidad",
                      showConfirmButton: false,
                      timer: 1500
                  })
                  console.log(error)
              }
          })
      }else{
          setErrorName(true)
      }
  };


  const handleClose = () => {
      refresh_causas();
      setOpen(false)
  };




  return(
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={handleClose}
    closeAfterTransition
    slots={{ backdrop: Backdrop }}
    slotProps={{
        backdrop: {
            timeout: 500,
        },
    }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <form onSubmit={onSubmit}>
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{p:0, mb:1, pr:1}} className="center_item">
                <Typography id="transition-modal-title" variant="h6" component="h2">
                Nueva Causa de Devolución
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{p:0, mb:1, pr:1}} >
                <TextField
                    id="caus_texto"
                    label="Causa"
                    name="caus_texto"
                    size="small"
                    fullWidth
                    onChange={(e)=> {
                        e.target.value.length > 0 ?  
                        setErrorName(false) :
                        setErrorName(true)
                    }}  
                    error={errorName}
                    helperText={errorName ? 'Ingrese una causa' : ''}
                />
              </Grid>
              <Grid item xs={12} sx={{p:0, mb:1, pr:1, mt:2}} >
                <Button variant="contained" 
                        className="btn_system" 
                        type="submit"
                >
                    Crear Causa
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Fade>
    </Modal>
  )
}