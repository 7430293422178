import React, { useContext } from "react";
import { 
	Grid,
    InputAdornment,
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
		TextField
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "@emotion/react";
import { TableVirtuoso } from "react-virtuoso";
import { Search } from "@mui/icons-material";
import { GroupingTable } from "../../../../components/Table/grouping_table";

const TablaClientes = ({sample, filtro, handleInputChange, clientesFiltrados }) => {

    const theme = useContext(ThemeContext);
    const navigate = useNavigate();

    const sort = {}
    const columns = [
			{
				accessorKey: 'emp_razonsocial',
				header: 'Razon social',
			},
			{
				accessorKey: 'emp_rfc',
				header: 'RFC',
			},
			{
				accessorKey: 'emp_codigo',
				header: 'Código',
			},
			{
				accessorKey: 'emp_estatus',
				header: 'Estatus',
				Cell: ({ cell }) => (cell.getValue() === 1 ? 'Activo' : 'Inactivo'),
			},
    ]

    const redirect = (row) => {
      if(row){
        navigate(`/inventarios/clientes/${row?.emp_id}`);
      }
    }

    return(
        <Paper style={{ height: '100%', width: '100%' }} sx={{boxShadow:'none'}}>
          {/* <Grid container spacing={0}>
              <Grid item xs={12} sx={{p:1, justifyContent: 'flex-end'}} className="center_item">
                  <TextField
                  id="search_permission"
                  label="Ingrese palabra clave"
                  name="search_permission"
                  size="small"
                  value={filtro}
                  onChange={handleInputChange}
                  InputProps={{
                      startAdornment: (
                      <InputAdornment position="start">
                          <Search sx={{fontSize:20}}/>
                      </InputAdornment>
                      ),
                  }}
                  sx={{ color: theme.palette.primary.input }}
                  />
              </Grid>
          </Grid> */}
          <GroupingTable  
          key='tabla_clientes' 
          columnas={columns} 
          data={clientesFiltrados} 
          redirect={redirect}
          sort={sort}
          />
        </Paper>
    )
}

export default TablaClientes;