import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const EnviarDevolucion = ()  => {
    const api = useApi();
    const mutate = async (params) => {
        const data  = await api.post(`/administracion-inventario/crear/orden/v2/devolucion/contenedores/nuevos/movimientos/etiquetas`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        enviarDevolucionMutation: mutation.mutate,
    };
}

export const GetDevolucion = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/documentos/devolucion/data/${params}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getDevolucionMutation: mutation.mutate,
    };
}

export const GetTagDevolucion = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/etiquetas/devolucion/data/${params}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getTagDevolucionMutation: mutation.mutate,
    };
}