import { ExpandMore } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Typography } from "@mui/material"


export const SideList = ({entities, selectedTo, handleSelect, side, rightList}) => {

  const isDisabledInit = (col) => {
    return side === 'left' && rightList?.length === 0 && selectedTo.length > 0 && !selectedTo?.some(item => 
      item.name === col.main_entity ||
      item?.columns?.some(column => 
        column.relation_name === col.main_entity || column.target_entity_inter === col.main_entity
      )
    )
  }

  const isDisabledInProcess = (col) => {
    let res = side === 'left' && rightList?.length > 0 && !selectedTo?.some(item => 
      item.name === col.main_entity ||
      item?.columns?.some(column => 
        column.relation_name === col.main_entity || column.target_entity_inter === col.main_entity
      )
    ) &&
    !rightList?.some(item => 
      item.name === col.main_entity ||
      item?.columns?.some(column => 
        column.relation_name === col.main_entity || column.target_entity_inter === col.main_entity
      )
    )
    return res;
  }

  const chooseTitle = (col) => {
    if(col.target_entity_inter) return col.target_entity_inter
    if(!col.target_entity_inter && (col.relation_name === col.name) ) return col.name
    if(!col.target_entity_inter && col.relation_name && (col.relation_name !== col.name) ) return `${col.relation_name} (${col.name})`
    return col.name
  }
  const chooseName = (ent) => {
    if(ent?.alias){
      return `${ent.name} (${ent.alias})`
    }else{
      return `${ent.name}`
    }
  }

  return(
    <div key={`${side}_div`} style={{width: '100%', height:'78vh', overflow:'scroll', padding:'5px'}}>
      {entities.map((entity) => (
        <Accordion key={`${entity.name}_${side}_${entity?.alias ? entity.alias : 'not_alias'}`}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>{chooseName(entity)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {entity?.columns?.map((col) => (
              <div key={`${col.name}${entity?.alias ? entity.alias : 'not_alias'}`} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Checkbox
                  key={`${col.name}_${side}_check_${entity?.alias ? entity.alias : 'not_alias'}`}
                  disabled={ (isDisabledInit(col) ||  isDisabledInProcess(col))}
                  checked={selectedTo?.some(item => item.name === entity?.name)?.columns?.some(item=>item.name === col.name)}
                  onChange={() => handleSelect(side, col, entity?.alias)}
                />
                <Typography key={`${col.name}_${side}_check_title_${entity?.alias ? entity.alias : 'not_alias'}`}>{chooseTitle(col)}</Typography>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}