import { Button, Chip, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material"
import validateNumber from "../../../helpers/validateInt"
import { TipoSplitEnum } from "../../../@enums/tipo_split";
import { ThemeContext } from "@emotion/react";
import { useContext, useState } from "react";
import { Delete } from "@mui/icons-material";

export const ItemDevolucion = ({form_data, index, existencia, setExistencia, array_split, set_array_split, data, almacenes, update, causas_dev, setOpen}) => {

  const theme = useContext(ThemeContext);
  const [errorCant, setErrorCant] = useState(false);

  const changeCantidad = (index, value) => {
    let cant = 0;
    let rest = existencia;
    let movimientos = array_split;
    if(value !== '' && parseInt(value) > 0 ){
      cant = parseInt(value);
      setErrorCant(false)
      if(cant > (existencia+movimientos[index].cantidad)){
        cant = movimientos[index].cantidad + rest;
        form_data.current[`cantidad_${index}`].value = cant;
        rest = 0;
      }else{
        rest = existencia + (movimientos[index].cantidad-cant)
      }
      
    }else{
      setErrorCant(true)
      rest = existencia + movimientos[index].cantidad;
      cant = null
    }
    movimientos[index].containers = [];
    movimientos[index].cantidad = cant;
    movimientos[index].tipo = cant % 2 !== 0 ? TipoSplitEnum.IRREGULAR : TipoSplitEnum.REGULAR;
    setExistencia(rest)
    set_array_split([...movimientos])
  }
  const changeOtherData = (index, name, value) => {
    console.log(value)
    let movimientos = array_split;
    movimientos[index][name] = value;
    set_array_split([...movimientos])
  }
  const changeTipoSplit = (index, value) => {
    let array = array_split;
    array[index].tipo = value;
    array[index].containers = [];
    set_array_split([...array])
  };

  //CONTAINERS
  const fillDataIrregular = (index, value) => {
    let array = array_split;
    let piezas_paq = value;
    if (piezas_paq !== "") {
      let new_value = parseInt(value)
      let total = array[index].containers.reduce((acumulador, valorActual) => acumulador + valorActual, 0);
      let rest = array[index].cantidad - total
      if(rest > 0){
        if( new_value > rest){
          array[index].containers.push(array[index].cantidad - total)
        }else{
          array[index].containers.push(new_value)
        }
      }
    }
    form_data.current[`total_cantidad_irr_${index}`].value = "";
    set_array_split([...array])
  };
  const deletePaqueteIrr = (index,index_split) => {
    let array = array_split;
    let splits = array[index].containers
    splits.splice(index_split, 1);
    set_array_split([...array]);
  };
  const fillDataRegular = (index, value) => {
    let array = array_split;
    let contendedores = value;
    let exist = existencia;
    if (contendedores !== "") {
      let cont = parseInt(contendedores);
      let paquetes = new Array(cont);
      let cociente = Math.floor(array[index].cantidad / cont);
      let residuo = array[index].cantidad % cont;
      paquetes.fill(cociente);
      if(residuo > 0){
        paquetes.unshift(residuo)
      };
      array[index].containers = paquetes;
    } else {
      array[index].containers = []
    }
    set_array_split([...array]);
  };
  
  return (
    <>
      <Grid container spacing={0} sx={{border: "1px solid #D9D9D9", borderRadius: 5, mb:3, p:1, display: 'flex'}}>
        <Grid item xs={3} sx={{p:1}} className="center_item">
          <Grid container spacing={0} >
            <Grid item xs={12} sx={{p:1}} className="center_item">
            <FormControl fullWidth size="small" error={errorCant}>
              <TextField
                id={`cantidad_${index}`}
                size="small"
                label="Cantidad"
                fullWidth
                required
                defaultValue={data?.cantidad}
                disabled={update}
                onKeyDown={(e)=> validateNumber(e)}
                onKeyUp={(e)=>changeCantidad(index, e.target.value)}
                sx={{color: theme.palette.primary.input}}
              />
              <FormHelperText>
                {errorCant ? "Ingrese una cantidad válida" : ""}
              </FormHelperText>
            </FormControl>
            </Grid>
            <Grid item xs={12} sx={{p:1}} className="center_item">
              {
                update ? (
                  <TextField
                  id={`alma_id_${index}`}
                  size="small"
                  label="Almacen"
                  fullWidth
                  required
                  defaultValue={data?.almacen}
                  disabled={update}
                  sx={{color: theme.palette.primary.input}}
                />
                ):(
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-alm">Almacen</InputLabel>
                    <Select
                      labelId="demo-simple-select-alm"
                      id="alma_id"
                      name="alma_id"
                      label="Almacen"
                      required
                      value={parseInt(data?.alma_id)}
                      onChange={(e)=>changeOtherData(index, 'alma_id', parseInt(e.target.value))}
                    >
                      {almacenes?.map((alm) => (
                        <MenuItem value={alm?.id}>{alm?.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              }
            </Grid>
            <Grid item xs={12} sx={{p:1}} className="center_item">
              <FormControl fullWidth size="small" sx={{ width: "100%" }}>
                <InputLabel id="select-split">Tipo registro</InputLabel>
                <Select
                  labelId="select-split"
                  id="tipo_spliteo"
                  name="tipo_spliteo"
                  label="Tipo Spliteo"
                  disabled={update}
                  value={update ? 1 : data?.tipo}
                  onChange={(e) => changeTipoSplit(index, parseInt(e.target.value))}
                  required
                >
                <MenuItem value={1}>Regular</MenuItem>
                <MenuItem value={2}>Irregular</MenuItem>
              </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{p:1}} className="center_item">
              {data?.tipo === TipoSplitEnum.REGULAR && (
                <>
                  <TextField
                    id={`total_contenedores_${index}`}
                    size="small"
                    label="N° de Contenedores"
                    type="number"
                    fullWidth
                    required                
                    onKeyDown={(e) => validateNumber(e)}
                    onKeyUp={(e)=>fillDataRegular(index, e.target.value)}
                    disabled = {data?.cantidad === null || update}
                    sx={{ color: theme.palette.primary.input, width: "100%" }}
                  />
                </>
              )}
              {data?.tipo === TipoSplitEnum.IRREGULAR && (
                <>
                  <TextField
                    id={`total_cantidad_irr_${index}`}
                    size="small"
                    label="Cantidad"
                    fullWidth
                    required
                    disabled = {data?.cantidad === null || update}
                    onKeyDown={(e) => validateNumber(e)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        fillDataIrregular(index, e.target.value);
                      }
                    }}
                    sx={{ color: theme.palette.primary.input, width: "100%" }}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} sx={{p:1}} className="center_item">
              {
                update ? (
                  <TextField
                  id={`causa_${index}`}
                  size="small"
                  label="Causa devolución"
                  fullWidth
                  required
                  defaultValue={data?.causa?.caus_texto}
                  disabled={update}
                  sx={{color: theme.palette.primary.input}}
                />
                ):(
                <FormControl fullWidth size="small">
                  <InputLabel id="causa_devolucion_id" >Causa devolución</InputLabel>
                  <Select
                    labelId="causa_devolucion_id"
                    id="causa_devolucion_id"
                    label="Causa devolución"
                    value={data?.causa?.caus_id}
                    onChange={(e)=>{
                      let causa = causas_dev.find(item => item.caus_id === parseInt(e.target.value))
                      changeOtherData(index, 'causa', {caus_id: causa?.caus_id, caus_texto: causa?.caus_texto} )
                    }}
                    input={<OutlinedInput label="Causa devolución"/>}
                    fullWidth
                  >
                    
                    <MenuItem key={'btn_uni_new'}>
                      <Button variant="contained" 
                        className="btn_system" 
                        sx={{height:25}}
                        onClick={() => setOpen(true)}
                      >
                          + Nueva causa
                      </Button>
                    </MenuItem>
                    {causas_dev?.map((caus)=> (
                      <MenuItem key={caus?.caus_id} value={caus?.caus_id}>
                        {caus?.caus_texto}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                )
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={9} className="container_split" >
          <Grid
            container
            spacing={0}
            sx={{
              p: 1,
              textAlign: "start",
              display: "flex",
              alignItems: "center",
              border: '1px solid #D9D9D9',
              borderRadius: 2,
              width: 'auto'
            }}
          >
            {data?.containers?.map((paq, index_split) => (
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={2}
                xl={2}
                sx={{
                  p: 1,
                  textAlign: "start",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Chip
                  label={
                    <div
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          marginRight: 7,
                          fontSize: 14,
                          fontWeight: 400,
                          color: !update && data?.tipo === TipoSplitEnum.REGULAR && 
                                data?.containers.length > index_split+1 &&
                                paq !== data?.containers[index_split+1] ? 
                                "#F57C00" : "black",
                        }}
                      >
                        {update ?  paq?.cantidad : paq}
                      </span>
                      {
                        update && 
                        <span
                          style={{
                            marginLeft: 7,
                            border: "1px solid #575E3B",
                            borderRadius: 4,
                            fontSize: 11,
                            padding: 3,
                            color: "#575E3B"
                          }}
                        >
                          {paq?.tag}
                        </span>
                      }
                    </div>
                  }
                  variant="outlined"
                  deleteIcon={
                    data?.tipo === TipoSplitEnum.IRREGULAR ? (
                      <Delete sx={{ marginLeft: "18px !important" }} />
                    ) : null
                  }
                  onDelete={
                    data?.tipo === TipoSplitEnum.IRREGULAR
                      ? () => deletePaqueteIrr(index, index_split)
                      : null
                  }
                  sx={{
                    height: 40,
                    borderRadius: 2,
                    display: "flex",
                    justifyContent:
                    data?.tipo === TipoSplitEnum.IRREGULAR
                        ? "flex-end"
                        : "center",
                    width: "100%",
                    backgroundColor:"transparent",
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}