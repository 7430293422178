import { useMutation } from "react-query";
import useApi from "./useApiRequest";

export const GetTagAjuste = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/etiquetas/ajuste/data?code=${params.code}&alm=${params.alm}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getTagAjusteMutation: mutation.mutate,
    };
}
export const CreateAjuste = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/ajustes-inventario`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        crearAjusteMutation: mutation.mutate,
    };
}
export const GetAjuste = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.get(`/ajustes-inventario/${params}`);
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        getAjusteMutation: mutation.mutate,
    };
}
export const ReactivarEtiqueta = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.put(`/ajustes-inventario/reactivar/${params}`, {}, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        reactivarEtiquetaMutation: mutation.mutate,
    };
}

//NEW

export const CreateOrdenAjuste = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/ajustes-inventario/crear-ajuste`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        createOrdenAjusteMutation: mutation.mutate,
    };
}

export const AddTag = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/ajustes-inventario/add-tag`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        addTagMutation: mutation.mutate,
    };
}

export const CloseAjuste = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/ajustes-inventario`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        closeAjusteMutation: mutation.mutate,
    };
}

export const CloseSalidaInventario = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.post(`/ajustes-inventario/salida-inventario`, params, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);

    return {
        closeSalidaInventarioMutation: mutation.mutate,
    };
}

export const RemoveTagOrderOpen = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.del(`/ajustes-inventario/tag/${params.id}/${params.tipo}`, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);
    
    return {
        removeTagOrderOpenMutation: mutation.mutate,
    };
}

export const UndoSalidaInventario = ()  => {
    const api = useApi();

    const mutate = async (params) => {
        const data  = await api.del(`/ajustes-inventario/salida-inventario/undo/complete/${params}`, {
            dataType: "json",
            hasFormData: false,
            hasCustomUrl: false,
        });
        return data.data;
    };

    const mutation = useMutation(mutate);
    
    return {
        undoSalidaInventarioMutation: mutation.mutate,
    };
}