import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetDocumentos } from "../../../hooks/getDocumentosByTipo";
import { useEffect, useState } from "react";
import { TipoDocumentoEnum } from "../../../@enums/tipo_documento";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import { Button, Divider, Grid } from "@mui/material";
import { TablaDevoluciones } from "./tablaDevoluciones";

export const DevoluvionesIndex = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {getDocumentosMutation} = GetDocumentos();
  const [returns, setReturns] = useState([]);
  const [filtro, setFiltro] = useState('');

  const goNuevo = () => {
    navigate(`/inventarios/devoluciones/nueva`);
  }
  const get_docs = () => {
      getDocumentosMutation({tipo:TipoDocumentoEnum.DEVOLUCION, clave: filtro}, {
          onSuccess: (data) => {
              setReturns(data)
          },
          onError: (error) => {
              console.log(error)
          }
      })
  }
  useEffect(() => {
      dispatch(
          HEADER_DATA_UPDATE({nombreData: null})
      )
  }, []);

  const handleInputChange = (event) => {
      setFiltro(event.target.value);
  };
  const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
          get_docs()
      }
  };

  return(
    <>
      <Grid container spacing={0} sx={{height:'10vh'}}>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} sx={{padding:'10px 30px', height:'100%'}} className="center_item">
          <Button variant="contained" className="btn_system" onClick={goNuevo}>
            + Nueva devolución
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={0} sx={{height:'80vh'}} >
        <Grid item xs={12} sx={{padding:'10px 20px', height:'100%'}} className="center_item">
          <TablaDevoluciones  key={'entries_table'} sample={returns} filtro={filtro} handleInputChange={handleInputChange} onPressEnter={handleKeyDown}/>
        </Grid>
      </Grid>
    </>
  )
}