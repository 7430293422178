import { Grid, TextField, Typography } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react"
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer"
import { useDispatch } from "react-redux"
import { useLoading } from "../../../components/Loading/useLoading"
import { ThemeContext } from "@emotion/react"
import { RecursosEnum } from "../../../@enums/tipo_images.enum"
import { PhotoLibrary } from "@mui/icons-material"
import CarouselImg from "../../../components/carousel"
import { ItemDevolucion } from "./itemDevolucion"
import moment from "moment"
import { useParams } from "react-router-dom"
import { GetDevolucion } from "../../../hooks/devolucion"


export const Devolucion = () => {

  const theme = useContext(ThemeContext);
  const formData = useRef(null);
  const { id } = useParams();
  const {getDevolucionMutation} = GetDevolucion();
  const {setAppLoading} = useLoading();
  const dispatch = useDispatch();
  const [headData, setHeadData] = useState(null)

  useEffect(() => {
    get_devolucion();
  }, []);

  const get_devolucion = () => {
    setAppLoading(false)
    getDevolucionMutation(id, {
      onSuccess: (data) => {
        if(data){
          dispatch(
            HEADER_DATA_UPDATE({nombreData: `Devolución Folio ${data?.folio}`})
        )
          setHeadData(data)
        }
      },
      onError: (error) => {

      }
    })
  }



  return(
    <>
    {
      headData !== null && 
      <form ref={formData} id="form_dom" style={{ width: "100%" }}>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center', mb:2}}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
              Datos Devolución
          </Typography>                
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={9} xl={9} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={4} sx={{p:1, display:'flex', justifyContent:'center'}}>
              <TextField
                id='dev_emp'
                fullWidth
                size="small"
                defaultValue={headData?.empresa}
                name="dev_emp"
                label='Cliente'
                disabled
                sx={{color: theme.palette.primary.input}}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={4} sx={{p:1, display:'flex', justifyContent:'center'}}>
              <TextField
                id='dev_part'
                fullWidth
                size="small"
                value={headData?.parte}
                name="dev_part"
                label='Parte'
                disabled
                sx={{color: theme.palette.primary.input}}
                className="input-date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
              <TextField
                id='dev_cant'
                fullWidth
                size="small"
                value={headData?.cantidad}
                name="dev_cant"
                hiddenLabel
                label='Cantidad'
                disabled
                sx={{color: theme.palette.primary.input}}
                className="input-date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={3} sx={{p:1, display:'flex', justifyContent:'center'}}>
              <TextField
                id='dev_ord'
                fullWidth
                size="small"
                value={headData?.orden}
                name="dev_ord"
                label='Orden'
                hiddenLabel
                disabled
                sx={{color: theme.palette.primary.input}}
                className="input-date"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{p:'0px 50px', textAlign:'start', display:'flex', justifyContent:'center', alignItems:'center'}}>
          {
            headData?.recursos?.filter
            ((res) => +res?.recu_tipo === RecursosEnum.PRINCIPAL)
            .length === 0 ?
            (
              <PhotoLibrary 
              sx={{
                color: '#575E3B',
                fontSize: 80
              }}
              />
            ) : 
            (
              <CarouselImg
              key={"carousel"}
              recursos={headData?.recursos?.filter(
                (res) => +res?.recu_tipo === RecursosEnum.PRINCIPAL
              )}
              />
            )
          }
        </Grid>
      </Grid>
      <Grid container spacing={0} sx={{mt:3}}>
        {
          headData?.containers?.map((split, index) => (
            <Grid item xs={12} sx={{p:1, textAlign:'start', display:'flex', justifyContent:'space-between', alignItems:'center', mb:2}}>
              <ItemDevolucion
              key={`dev_${index}`}
              index={index}
              data={split}
              update={true}/>
            </Grid>
          ))
        }
      </Grid>
    </form>
    }
    </>
  )
}