import useApi from "./useApiRequest";
import { useMutation } from "react-query";

export const ReetiquetarSalida = () => {
  const api = useApi();

  const mutate = async (params) => {
    const data  = await api.post(`/etiquetas/reetiquetado`, params, {
        dataType: "json",
        hasFormData: false,
        hasCustomUrl: false,
    });
    return data.data;
};

  const mutation = useMutation(mutate);

  return {
    reetiquetarSalidaMutation: mutation.mutate,
  };
};
