import { useEffect, useState, useRef } from "react";
import { Input, FormLabel, Button, Select, MenuItem } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ActualizarItemFormato } from "../../../../hooks/updateItemFormato";
import CamposDB from "../data/CamposDB.json";
import BarcodeDB from "../data/BarcodeDB.json";

export const EditItemForm = ({ defaultData, camposExtra, empresas }) => {
  const { register, handleSubmit, watch } = useForm({
    defaultValues: defaultData,
  });
  const valorInputRef = useRef(null);
  const [camposFiltrados, setCamposFiltrados] = useState([]);
  const empresa = watch("feit_empresa");
  const tipo = watch("feit_tipo");
  useEffect(() => {
    if (tipo == 6 && empresa != 0) {
      setCamposFiltrados(
        camposExtra.filter((campo) => campo.emp_id === empresa)
      );
    }
    if (empresa == 0) {
      setCamposFiltrados(camposExtra);
    }
  }, [tipo, empresa]);
  const navigate = useNavigate();
  const { actualizarFormatoItem } = ActualizarItemFormato();
  const onSubmit = (formData) => {
    delete formData.feit_empresa;
    actualizarFormatoItem(formData, {
      onSuccess: (data) => {
        navigate("/inventarios/etiquetas/" + data.foet_id);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };
  const InputNuevoItem = ({ id, label, placeholder, inputName, type, ref }) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
        <FormLabel>{label}</FormLabel>
        <Input
          id={id}
          type={type}
          placeholder={placeholder}
          {...register(inputName)}
          inputRef={ref}
        />
      </div>
    );
  };

  const SelectNuevoItem = ({
    label,
    placeholder,
    inputName,
    options,
    multiple = false,
    show = true,
  }) => {
    return (
      <div
        style={{
          display: show === true ? "flex" : "none",
          flexDirection: "column",
          width: "50%",
        }}
      >
        <FormLabel>{label}</FormLabel>
        <Select
          placeholder={placeholder}
          {...register(inputName)}
          defaultValue={defaultData[inputName]}
          value={watch(inputName)}
          multiple={multiple}
        >
          {options.map((option, index) => (
            <MenuItem value={option.value} key={index}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  function renderValorInput(tipo) {
    let options = [];
    let multiple = false; 
    switch (tipo) {
      case 2:
        break;
      case 3:
      case 9:
        options = CamposDB.map((campo) => ({
          value: campo.value,
          label: campo.label,
        }));
        multiple = true;
        break;
      case 5:
        options = BarcodeDB.map((barcode) => ({
          value: barcode.value,
          label: barcode.label,
        }));
        break;
      case 6:
        options = camposFiltrados.map((campo) => ({
          value: campo.came_nombre,
          label: campo.came_nombre,
        }));
        break;
      default:
        break;
    }
    if (tipo !== 3 && tipo !== 5 && tipo !== 6 ) {
      return (
        <>
          <FormLabel component="legend">Valor</FormLabel>
          <Input
            style={{ width: "50%" }}
            placeholder="Valor"
            {...register("feit_valor")}
            inputRef={valorInputRef}
          />
        </>
      );
    } else {
      return (
        <SelectNuevoItem
          id="feit_valor"
          label="Valor"
          placeholder="Valor"
          inputName={"feit_valor"}
          options={options}
          multiple={multiple}
        />
      );
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <InputNuevoItem
          label="Nombre"
          placeholder="Nombre"
          type={"text"}
          inputName={"feit_nombre"}
        />
        <SelectNuevoItem
          label="Tipo"
          placeholder="Tipo"
          inputName={"feit_tipo"}
          options={[
            { value: 1, label: "Texto" },
            { value: 2, label: "Linea" },
            { value: 3, label: "Campo DB" },
            { value: 5, label: "Código de barras" },
            { value: 6, label: "Campo Extra" },
            { value: 7, label: "Campo compuesto"},
            { value: 8, label: "Código QR"},
            { value: 9, label: "Código de barras PDF-417"},
            { value: 10, label: "Datamatrix"}
          ]}
        />
        <InputNuevoItem
          label="Pos. x"
          placeholder="Pos. x"
          type={"number"}
          inputName={"feit_x"}
        />
        <InputNuevoItem
          label="Pos. y"
          placeholder="Pos. y"
          type={"number"}
          inputName={"feit_y"}
        />
        <InputNuevoItem
          label="Altura"
          placeholder="Altura"
          type={"number"}
          inputName={"feit_h"}
        />
        <InputNuevoItem
          label="Ancho"
          placeholder="Ancho"
          type={"number"}
          inputName={"feit_w"}
        />
        {watch("feit_tipo") === 6 && (
          <>
            <FormLabel component="legend">Empresa</FormLabel>
            <Select
              defaultValue={0}
              {...register("feit_empresa")}
              style={{ width: "50%" }}
            >
              {empresas.map((empresa) => (
                <MenuItem value={empresa.emp_id} key={empresa.emp_id}>
                  {empresa.emp_rfc}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        {renderValorInput(watch("feit_tipo"))}
        <SelectNuevoItem
          label="Orientación"
          placeholder="Orientación"
          inputName={"feit_orientacion"}
          options={[
            { value: false, label: "Horizontal" },
            { value: true, label: "Vertical" },
          ]}
        />
        <Button type="submit" variant="contained">
          Guardar
        </Button>
      </div>
    </form>
  );
};
